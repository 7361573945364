<template>
  <main class="app__page architecture">
    <div class="architecture__container">
      <h1 class="architecture__title title title--bb">
        {{ $t("pages.architecture.title") }}
      </h1>

      <div class="architecture__main-wrap">
        <img
          class="architecture__main-img"
          src="/img/architecture/architecture1.jpg"
          alt="architecture1"
          @load="loaded += 1"
        />
      </div>
    </div>

    <div class="architecture__bg-word-wrap">
      <img
        class="architecture__bg-word"
        src="/img/architecture/architecture.svg"
        alt="architecture"
        @load="loaded += 1"
      />
    </div>

    <div v-if="pageLocale" class="architecture__content-wrapper">
      <div class="architecture__bg-img bg-img"></div>

      <div class="architecture__main-info">
        <div class="architecture__main-pattern is-observable"></div>

        <div class="architecture__container architecture__container--fd">
          <div class="architecture__main-text-observer is-observable">
            <p class="architecture__main-text observe-target observe-80-bottom">
              <span class="architecture__main-text-inner">
                <span class="architecture__main-line">
                  <span class="architecture__main-name">
                    {{ pageLocale.main_paragraph[0] }}
                  </span>
                  {{ pageLocale.main_paragraph[1] }}
                </span>
                <span class="architecture__main-line">
                  {{ pageLocale.main_paragraph[2] }}
                </span>
                <span
                  class="architecture__main-line architecture__main-line--left"
                >
                  {{ pageLocale.main_paragraph[3] }}</span
                >
              </span>
              <span
                class="architecture__main-line architecture__main-line--italic"
              >
                <span class="architecture__main-word-tr">
                  {{ pageLocale.main_paragraph[4] }}
                </span>
                {{ pageLocale.main_paragraph[5] }}
              </span>
            </p>
          </div>

          <div class="architecture__info">
            <p
              class="
                architecture__info-text architecture__info-text--pt
                is-observable
              "
            >
              <span class="d-block observe-target observe-40-right">
                {{ pageLocale.info[0] }}
              </span>
            </p>
            <div class="architecture__info-img-wrap is-observable">
              <img
                class="architecture__info-img observe-target observe-80-bottom"
                src="/img/architecture/architecture2.jpg"
                alt="architecture2"
              />
            </div>
            <p
              class="
                architecture__info-text architecture__info-text--mb0
                is-observable
              "
            >
              <span class="d-block observe-target observe-80-bottom">
                {{ pageLocale.info[1] }}
              </span>
            </p>
          </div>
        </div>
      </div>

      <div class="architecture__block-building">
        <img
          class="architecture__img-cloud"
          src="/img/architecture/cloud1.png"
          alt="cloud"
        />
        <img
          class="architecture__img-words"
          src="/img/architecture/words.svg"
          alt="modern complex"
        />
        <div class="architecture__building-wrap">
          <img
            class="architecture__img-building"
            src="/img/architecture/building.png"
            alt="building"
          />
        </div>

        <div class="architecture__building-info">
          <p class="architecture__building-text">
            {{ pageLocale.building_block[0] }}
          </p>
          <p class="architecture__building-text2 is-observable">
            <span class="d-block observe-target observe-80-bottom">
              {{ pageLocale.building_block[1] }}
            </span>
          </p>
          <p class="architecture__building-text3 is-observable">
            <span class="d-block observe-target observe-80-bottom">
              {{ pageLocale.building_block[2] }}
            </span>
          </p>
        </div>
      </div>

      <div class="architecture__block">
        <div class="architecture__semicircles"></div>
        <div class="architecture__container architecture__container--2">
          <div class="architecture__container-2-pattern is-observable"></div>

          <div class="architecture__block-inner">
            <!-- <p class="architecture__info-text architecture__info-text--adapt1">
              {{ pageLocale.info2[0] }}
            </p>
            <p class="architecture__info-text architecture__info-text--3">
              <span class="d-block observe-target observe-80-bottom">
                {{ pageLocale.info2[1] }}
              </span>
            </p>-->
            <div class="architecture__list-wrapper">
              <b>
                {{ pageLocale.info_list[0] }}
              </b>
              <ul class="architecture__list">
                <li class="architecture__list-li">
                  {{ pageLocale.info_list[1] }}
                </li>
                <li class="architecture__list-li">
                  {{ pageLocale.info_list[2] }}
                </li>
                <li class="architecture__list-li">
                  {{ pageLocale.info_list[3] }}
                </li>
                <li class="architecture__list-li">
                  {{ pageLocale.info_list[4] }}
                </li>
              </ul>
            </div>
            <div class="architecture__info-img3-pic is-observable">
              <img
                class="architecture__info-img3 observe-target observe-80-bottom"
                src="/img/architecture/architecture3.jpg"
                alt="architecture3"
              />
            </div>

            <!-- <p
              class="
                architecture__info-text architecture__info-text--adapt2
                is-observable
              "
            >
              <span class="d-block observe-target observe-80-bottom">
                {{ pageLocale.info2[2] }}
              </span>
            </p> -->
          </div>
          <div class="architecture__block2">
            <p
              class="
                architecture__info-text architecture__info-text--w650
                is-observable
              "
            >
              <span class="d-block observe-target observe-80-bottom">
                {{ pageLocale.info3[0] }}
              </span>
            </p>

            <p
              class="
                architecture__info-text architecture__info-text--w650
                is-observable
              "
            >
              <span class="d-block observe-target observe-80-bottom">
                {{ pageLocale.info3[1] }}
              </span>
            </p>

            <p
              class="
                architecture__info-text
                architecture__info-text--w900
                architecture__info-text--4
                is-observable
              "
            >
              <span class="d-block observe-target observe-80-bottom">
                {{ pageLocale.info3[2] }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="architecture__container-2500">
      <localized-link to="/design" class="next-page">
        <span class="next-page__capiton">{{ $t("next") }}</span>
        <span class="next-page__name">
          <span class="next-page__title">
            {{ $t("pages.design.title") }}
          </span>
          <span class="next-page__btn r-btn">
            <svg-icon
              width="14"
              height="10"
              name="arrow_right"
              class="btn-icon"
            />
          </span>
        </span>
      </localized-link>
    </div>
    <PageFooter />
  </main>
</template>

<script>
import { pageLocale } from "@/mixins/pageLocale.js";
import { observer } from "@/mixins/observer.js";
import PageFooter from "@/components/PageFooter";
export default {
  name: "architecture",
  components: { PageFooter },
  mixins: [pageLocale, observer],
  data() {
    return {
      loadQty: 4 // смонтировано + перевод + 2 изображения
    };
  }
};
</script>
<style lang="less">
.architecture {
  .padding-page-top;
  position: relative;
  &__container {
    .container;
    &--2 {
      position: relative;
      padding-top: 90px;
      @media @bw1510 {
        padding-top: 100px;
      }
      @media @bw768 {
        padding-top: 80px;
      }
    }
    &--fd {
      @media @bw1510 {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  &__container-2-pattern {
    position: absolute;
    top: 0;
    left: 0px;
    width: 90px;
    height: 376px;

    @media @bw1510 {
      left: 13px;
      width: 72px;
      height: 332px;
    }
    @media @bw1340 {
      left: 64px;
    }
    @media @bw1170 {
      top: -24px;
      left: 0;
      width: 52px;
      height: 294px;
    }
    @media @bw768 {
      width: 20px;
      height: 94px;
    }
    @media @bw400 {
      width: 15px;
      height: 80px;
    }
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: @gold;
      transform-origin: 0 50%;
      transition: transform 1s ease-out;
    }
    &.is-observable {
      &::before {
        transform: scaleX(0);
      }
    }
    &.is-observed {
      &::before {
        transform: scaleX(1);
      }
    }
  }
  &__main-wrap {
    position: relative;
    width: 100%;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 0;
      padding-top: 53.03%;
      @media @bw1510 {
        padding-top: 52.886%;
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 123px;
      left: -46px;
      width: 91px;
      height: 311px;
      background-color: @gold;
      z-index: 1;
      @media @bw1510 {
        top: 77px;
        left: -36px;
        width: 72px;
        height: 247px;
      }
      @media @bw1170 {
        display: none;
      }
      @media @bw768 {
        display: block;
        top: 23px;
        left: -10px;
        width: 20px;
        height: 93px;
      }
      @media @bw400 {
        top: 14px;
        height: 80px;
      }
    }
  }
  &__main-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0px 50px 100px rgba(51, 57, 66, 0.6);
    border-radius: 2px;
  }
  &__bg-word-wrap {
    position: relative;
    margin-top: -165px;
    max-height: 288px;
    @media @bw1680 {
      margin: -95px 56px 65px;
    }
    @media @bw1510 {
      margin: -95px 56px 50px;
    }
    @media @bw1170 {
      margin: -70px 15px 50px;
    }
    @media @bw768 {
      margin: -35px 15px 0px;
    }
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 0;
      padding-top: 16.647%;
    }
  }
  &__bg-word {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    pointer-events: none;
  }
  &__main-info {
    position: relative;
    margin-bottom: 420px;
    padding-top: 130px;
    @media @bw1510 {
      margin-bottom: 323px;
      padding-top: 120px;
    }
    @media @bw1170 {
      margin-bottom: 160px;
      padding-top: 110px;
    }
    @media @bw768 {
      margin-bottom: 90px;
      padding-top: 60px;
    }
    @media @bw400 {
      margin-bottom: 60px;
    }
  }
  &__main-pattern {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 232px;
    @media @bw1510 {
      width: 53px;
      height: 186px;
    }
    @media @bw1170 {
      width: 31px;
      height: 138px;
    }
    @media @bw768 {
      width: 13px;
      height: 94px;
    }
    @media @bw400 {
      display: none;
    }
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: @gold;
      transform-origin: 100% 50%;
      transition: transform 1.2s ease-out;
    }
    &.is-observable {
      &::before {
        transform: scaleX(0);
      }
    }
    &.is-observed {
      &::before {
        transform: scaleX(1);
      }
    }
  }
  &__main-text {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    margin-left: 215px;
    margin-bottom: 140px;
    max-width: 590px;
    width: 100%;
    font-weight: 500;
    color: @white;
    font-size: 36px;
    line-height: 1.2;
    @media @bw1510 {
      margin-left: 150px;
    }
    @media @bw1170 {
      margin-bottom: 115px;
      margin-left: 75px;
      max-width: 420px;
      font-size: 24px;
    }
    @media @bw768 {
      margin-bottom: 100px;
      margin-left: 0;
      max-width: 315px;
      font-size: 20px;
    }
    @media @bw400 {
      margin-bottom: 80px;
      max-width: 100%;
      font-size: 18px;
    }
  }
  &__main-text-inner {
    display: flex;
    flex-direction: column;
    max-width: 550px;
    @media @bw1170 {
      max-width: 370px;
    }
    @media @bw768 {
      max-width: 305px;
    }
    @media @bw400 {
      max-width: 280px;
    }
  }
  &__main-name {
    background-color: @gold;
  }
  &__main-line {
    text-align: right;
    &--left {
      text-align: left;
    }
    &--italic {
      font-style: italic;
    }
  }
  &__main-word-tr {
    color: transparent;
    -webkit-text-stroke: 1px @white_60;
    text-stroke: 1px @white_60;
    font-style: normal;
  }
  &__info {
    position: relative;
    padding-left: 1000px;
    @media @bw1510 {
      padding-left: 712px;
    }
    @media @bw1170 {
      padding-left: 420px;
    }
    @media @bw768 {
      padding-left: 0;
      display: flex;
      flex-direction: column;
    }
  }
  &__info-img-wrap {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 900px;
    @media @bw1510 {
      max-width: 626px;
    }
    @media @bw1170 {
      max-width: 373px;
    }
    @media @bw768 {
      position: relative;
      margin-bottom: 30px;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      bottom: -62px;
      left: -82px;
      width: 275px;
      height: 275px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid @gold;
      z-index: -1;
      @media @bw1510 {
        width: 257px;
        height: 257px;
        left: -70px;
        bottom: -103px;
      }
      @media @bw1170 {
        left: -50px;
        bottom: -57px;
        width: 170px;
        height: 170px;
      }
      @media @bw768 {
        left: -42px;
        bottom: -23px;
        width: 102px;
        height: 102px;
      }
    }
    &::after {
      left: -42px;
      bottom: -80px;
      border: 1px solid @white;
      @media @bw1510 {
        left: -30px;
        bottom: -120px;
      }
      @media @bw1170 {
        left: -24px;
        bottom: -67px;
      }
      @media @bw768 {
        left: -26px;
        bottom: -30px;
      }
    }
  }
  &__img-words {
    position: absolute;
    left: 0;
    bottom: 20px;
    max-width: 1208px;
    z-index: -2;
    @media @bw1510 {
      bottom: 90px;
      max-width: 736px;
    }
    @media @bw1170 {
      bottom: 10px;
      max-width: 515px;
    }
    @media @bw768 {
      bottom: 185px;
      max-width: calc(100% - 150px);
    }
    @media @bw400 {
      bottom: 170px;
      max-width: calc(100% - 133px);
    }
  }
  &__info-text {
    margin: 0 0 30px;
    max-width: 260px;
    width: 100%;
    font-size: 16px;
    line-height: 1.5;
    color: @white_80;
    @media @bw1170 {
      max-width: 288px;
    }
    @media @bw768 {
      align-self: flex-end;
      max-width: calc(100% - 70px);
    }
    @media @bw400 {
      max-width: calc(100% - 30px);
    }
    &--3 {
      max-width: 450px;
      text-align: right;
      font-weight: 700;
      font-size: 18px;
      color: @white;
      @media @bw1020 {
        font-size: 16px;
      }
      @media @bw768 {
        text-align: left;
      }
    }
    &--4 {
      align-self: flex-start;
      font-weight: 600;
      font-size: 20px;
      color: @white;
      @media @bw1020 {
        font-size: 18px;
      }
    }
    &--pt {
      padding-top: 55px;
      @media @bw1510 {
        padding-top: 0;
      }
    }
    &--mb {
      margin-bottom: 135px;
      @media @bw1510 {
        margin-bottom: 52px;
      }
      @media @bw1170 {
        margin-bottom: 0;
      }
    }
    &--mb0 {
      margin-bottom: 0;
    }
    &--adapt1 {
      padding-top: 100px;
      max-width: 340px;
      width: 100%;
      @media @bw1510 {
        padding-top: 52px;
      }
      @media @bw1170 {
        padding-top: 0;
        max-width: 274px;
      }
      @media @bw768 {
        max-width: 100%;
      }
    }
    &--adapt2 {
      max-width: 340px;
      width: 100%;
      @media @bw1170 {
        max-width: 274px;
      }
      @media @bw768 {
        max-width: calc(100% - 70px);
      }
      @media @bw400 {
        max-width: calc(100% - 30px);
      }
    }
    &--w650 {
      max-width: 650px;
    }
    &--w900 {
      margin-top: 50px;
      max-width: 900px;
      @media @bw768 {
        margin-top: 30px;
      }
    }
  }
  &__block-building {
    position: relative;
    margin-bottom: 65px;
    @media @bw1170 {
      margin-bottom: 0px;
    }
  }
  &__img-cloud {
    position: absolute;
    left: 0;
    top: -90px;
    z-index: -3;
    width: 398px;
    height: 836px;
    @media @bw1510 {
      top: -10px;
      width: 300px;
      height: 660px;
    }
    @media @bw1170 {
      top: -30px;
      width: 230px;
      height: 514px;
    }
    @media @bw768 {
      top: 25px;
      width: 140px;
      height: 375px;
    }
    @media @bw400 {
      top: 60px;
      width: 80px;
      height: 313px;
    }
  }
  &__building-wrap {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 744px;
    height: 1238px;
    overflow: hidden;
    z-index: -1;
    @media @bw1510 {
      width: 470px;
      height: 1058px;
    }
    @media @bw1170 {
      bottom: 70px;
      width: 275px;
      height: 672px;
    }
    @media @bw768 {
      width: 175px;
      height: 400px;
    }
  }
  &__img-building {
    position: absolute;
    left: 0;
    bottom: 0;
    max-width: 744px;
    @media @bw1510 {
      max-width: 635px;
    }
    @media @bw1170 {
      max-width: 404px;
    }
    @media @bw768 {
      max-width: 240px;
    }
  }
  &__building-info {
    .container;
    display: flex;
    flex-direction: column;
  }
  &__building-text {
    margin: 0 0 40px 210px;
    max-width: 590px;
    width: 100%;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.2;
    color: @white;
    @media @bw1510 {
      margin: 0 0 40px 150px;
    }
    @media @bw1170 {
      margin: 0 0 30px 70px;
      max-width: 391px;
      font-size: 24px;
    }
    @media @bw768 {
      margin: 0 0 30px 0;
      max-width: 100%;
      font-size: 20px;
    }
    @media @bw400 {
      font-size: 18px;
    }
  }
  &__building-text2 {
    margin: 0 0 460px 300px;
    max-width: 388px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
    color: @white_80;
    @media @bw1510 {
      margin: 0 0 330px 240px;
    }
    @media @bw1170 {
      margin: 0 0 200px 120px;
      max-width: 294px;
    }
    @media @bw768 {
      margin: 0 0 245px 70px;
      max-width: calc(100% - 70px);
    }
    @media @bw400 {
      margin: 0 0 180px 30px;
      max-width: calc(100% - 30px);
    }
  }
  &__building-text3 {
    margin: 0 320px 0 0;
    align-self: flex-end;
    max-width: 450px;
    width: 100%;
    text-align: right;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.3;
    color: @white;
    white-space: pre-wrap;
    @media @bw1510 {
      margin: 0 260px 0 0;
    }
    @media @bw1170 {
      margin: 0 160px 0 0;
      max-width: 350px;
      font-weight: 500;
      font-size: 20px;
    }
    @media @bw768 {
      margin: 0 40px 0 0;
      max-width: 300px;
      font-size: 18px;
    }
    @media @bw400 {
      margin: 0 20px 0 0;
      max-width: 290px;
      font-size: 16px;
    }
  }
  &__block {
    position: relative;
    margin-bottom: 150px;
    padding-bottom: 100px;
    @media @bw1170 {
      margin-bottom: 0;
      padding-bottom: 100px;
    }
  }
  &__semicircles {
    position: absolute;
    bottom: -225px;
    right: 0;
    width: 280px;
    height: 457px;
    overflow: hidden;
    z-index: -1;
    @media @bw1510 {
      bottom: -90px;
      width: 162px;
      height: 365px;
    }
    @media @bw1170 {
      bottom: -30px;
      width: 137px;
      height: 265px;
    }
    @media @bw768 {
      bottom: 145px;
      width: 75px;
      height: 185px;
    }
    @media @bw400 {
      display: none;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 430px;
      height: 430px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid @gold;
      @media @bw1510 {
        width: 345px;
        height: 345px;
      }
      @media @bw1170 {
        width: 250px;
        height: 250px;
      }
      @media @bw768 {
        width: 185px;
        height: 185px;
      }
    }
    &::after {
      top: 23px;
      left: 66px;
      border: 1px solid @white;
      @media @bw1510 {
        top: 24px;
        left: 33px;
      }
      @media @bw1170 {
        top: 13px;
        left: 33px;
      }
    }
  }
  &__block-inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    margin-bottom: 80px;
    margin-left: 100px;
    @media @bw1170 {
      margin-left: 70px;
      gap: 30px;
    }
    @media @bw768 {
      margin-bottom: 0px;
      display: flex;
      flex-direction: column;
      margin-left: 0;
      padding-right: 0;
    }
  }
  &__info-img3-pic {
    max-width: 566px;
    width: 100%;
    @media @bw1510 {
      max-width: 398px;
    }
    @media @bw1170 {
      max-width: 303px;
    }
    @media @bw768 {
      align-self: center;
      margin-bottom: 30px;
      max-width: 374px;
    }
    @media @bw400 {
      max-width: 100%;
    }
  }
  &__info-img3 {
    display: block;
  }
  &__container-2500 {
    .container-2500;
  }
  &__block2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__list-wrapper {
    font-size: 18px;
  }
  &__list {
    list-style: none;
    padding: 0;
    margin: 10px 0 0;
  }
  &__list-li {
    position: relative;
    padding-left: 15px;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    &::before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: @gold;
    }
  }
}
</style>
