<template>
  <div v-if="floors" class="floors" @wheel="onScroll($event)" v-keyup="onArrow">
    <div class="flats__clouds clouds">
      <div class="clouds__cloud1"></div>
      <div class="clouds__cloud2"></div>
      <div class="clouds__cloud3"></div>
    </div>
    <div class="floors__building">
      <img
        src="@/assets/images/building.png"
        alt="building"
        class="floors__img"
        @load="loaded += 1"
      />
      <svg
        class="floors__svg"
        viewBox="0 0 585 904"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g @mouseleave="hideTitle()">
          <path
            v-for="(path, pathIndex) in pathes"
            :key="pathIndex"
            :d="path"
            fill="currentColor"
            class="floors__floor-path"
            :class="{
              'floors__floor-path--available': availableObj[pathIndex + 1],
              'floors__floor-path--active': pathIndex == active - 1
            }"
            @click="setFloor(pathIndex + 1)"
            @mousemove="moveTitle($event, pathIndex + 1)"
          />
        </g>
      </svg>

      <div v-if="hasFloors" class="floors__arrows">
        <button
          type="button"
          class="floors__arrow r-btn r-btn--44"
          :class="{
            'r-btn--disabled':
              active == availableFloors[availableFloors.length - 1]
          }"
          @click="nextfloor()"
        >
          <svg-icon width="10" height="12" name="arrow_up" class="btn-icon" />
        </button>

        <div class="floors__arrows-info">
          <div class="floors__arrows-num">
            {{ active || "–" }}
          </div>
          <div class="floors__arrows-caption">
            {{ $t("params.floor") }}
          </div>
        </div>

        <button
          type="button"
          class="floors__arrow r-btn r-btn--44"
          :class="{
            'r-btn--disabled': active == availableFloors[0]
          }"
          @click="nextfloor(true)"
        >
          <svg-icon width="10" height="12" name="arrow_down" class="btn-icon" />
        </button>
      </div>

      <div
        class="floors__path-title"
        :class="{ 'floors__path-title--active': titleActive }"
        :style="'left:' + titleX + '; top:' + titleY"
      >
        <div v-if="titleFloor" class="floors__path-floor">
          {{ titleFloor }}
        </div>

        <div v-if="!titleFloor" class="floors__path-sold">
          {{ $t("status.sold") }}
        </div>
      </div>
    </div>

    <div class="floors__info-wrapper">
      <div v-if="active" class="floors__info">
        <div class="floors__scheme">
          <template v-for="(alf, alfIndex) in availableFloors" :key="alfIndex">
            <link
              rel="prefetch"
              as="image"
              :href="'/base/floors/' + alf + '.svg'"
            />
          </template>

          <template v-for="(af, afIndex) in availableFloors" :key="afIndex">
            <img
              v-show="af == active"
              class="floors__flat-img"
              :src="'/base/floors/' + (af > 2 && af < 23 ? 4 : af) + '.svg'"
              @load="onFloorLoad(af)"
            />
          </template>

          <svg
            class="floors__flat-highlight"
            :class="{ 'floors__flat-highlight--active': loadedFloors[active] }"
            viewBox="0 0 548 548"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="#6ECFA0">
              <template v-for="flt in floors[active]" :key="flt.id">
                <path
                  class="floors__flat-path"
                  :class="{
                    'floors__flat-path--active': flt.id == flat_id,
                    'floors__flat-path--reserved': flt.status === 'reserved',
                    'floors__flat-path--sold': flt.status === 'sold',
                    'floors__flat-path--rent': flt.status === 'rent'
                  }"
                  :d="flt.highlight"
                  @click="goTo(flt)"
                  @mouseenter="enterFlat(flt)"
                />
              </template>
            </g>
          </svg>

          <svg
            class="floors__flat-captions"
            :class="{ 'floors__flat-captions--active': loadedFloors[active] }"
            viewBox="0 0 548 548"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <filter x="-0.1" y="-0.08" width="1.2" height="1.2" id="bg-text">
                <feFlood flood-color="#0D0D0C" />
                <feComposite in="SourceGraphic" operator="xor" />
              </filter>
            </defs>
            <template v-for="fl in floors[active]" :key="fl.id">
              <g>
                <text
                  filter="url(#bg-text)"
                  fill="transparent"
                  :x="fl.textX"
                  :y="fl.textY"
                >
                  {{
                    fl.status === "free"
                      ? $t("status.free")
                      : fl.status === "reserved"
                      ? $t("status.reserved")
                      : fl.status === "rent"
                      ? $t("status.rent")
                      : $t("status.sold")
                  }}
                </text>
                <text fill="#F3F3F2" :x="fl.textX" :y="fl.textY">
                  {{
                    fl.status === "free"
                      ? $t("status.free")
                      : fl.status === "reserved"
                      ? $t("status.reserved")
                      : fl.status === "rent"
                      ? $t("status.rent")
                      : $t("status.sold")
                  }}
                </text>
              </g>
            </template>
          </svg>
        </div>

        <div v-if="flat_id && loadedFloors[active]" class="floors__specs">
          <div class="floors__specs-note">
            <localized-link to="/documentation" class="floors__note-link">
              {{ $t("price_note") }}
            </localized-link>
          </div>

          <div class="floors__spec">
            <div class="floors__spec-data">
              {{ flat_id }}
            </div>
            <div class="floors__spec-caption">
              {{ $t("params.id") }}
            </div>
          </div>
          <div class="floors__spec">
            <div class="floors__spec-data">
              {{ flats[flat_id].rooms }}
            </div>
            <div class="floors__spec-caption">
              {{ $t("params.rooms") }}
            </div>
          </div>
          <div class="floors__spec">
            <div class="floors__spec-data">
              {{ flats[flat_id].area }}
            </div>
            <div class="floors__spec-caption">
              {{ $t("params.area") }}
            </div>
          </div>
          <div class="floors__spec">
            <div class="floors__spec-data">
              {{ $t("finish." + flats[flat_id].finish) }}
            </div>
            <div class="floors__spec-caption">
              {{ $t("params.finish") }}
            </div>
          </div>
          <div class="floors__spec">
            <div class="floors__spec-data">
              {{ $t("status." + flats[flat_id].status) }}
            </div>
            <div class="floors__spec-caption">
              {{ $t("params.status_caption") }}
            </div>
          </div>
          <div class="floors__spec">
            <button
              type="button"
              class="floors__spec-btn"
              :class="{
                'floors__spec-btn--disabled': flats[flat_id].status === 'sold'
              }"
              @click="openRequestFlat(flat_id)"
            >
              {{
                flats[flat_id].price_on_request
                  ? $t("requestPrice")
                  : $global.numberSpaces(flats[flat_id].price) || "-"
              }}
            </button>
            <span class="floors__spec-prompt">
              {{ $t("pages.flats-flat-id.btn_request") }}
            </span>
            <div class="floors__spec-caption">
              {{ $t("params.price") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pathes from "@/assets/json/floors.json";

export default {
  emits: ["loaded", "openRequest"],
  data() {
    return {
      pathes: pathes,
      titleActive: false,
      titleX: "110%",
      titleY: "110%",
      titleFloor: "",
      loadedFloors: {},
      loaded: 0
    };
  },
  computed: {
    active() {
      return this.$store.state.floor;
    },
    flat_id() {
      return this.$store.state.flat_id;
    },
    flats() {
      return this.$store.state.flats;
    },
    floors() {
      return this.$store.getters.floors;
    },
    availableFloors() {
      return this.floors
        ? Object.keys(this.floors).filter((floor) => {
            return this.floors[floor].some((flat) => {
              return (
                flat.status === "free" ||
                flat.status === "reserved" ||
                flat.status === "rent"
              );
            });
          })
        : null;
    },
    availableObj() {
      return this.$global.arrToObj(this.availableFloors);
    },
    hasFloors() {
      return this.availableFloors && this.availableFloors.length;
    }
  },
  methods: {
    openRequestFlat(id) {
      if (!id) return;
      this.$emit("openRequest", id);
    },
    goTo(flat) {
      if (flat.status !== "sold") this.$router.push("/flats/flat/" + flat.id);
    },
    moveTitle(event, floor) {
      this.titleX = event.offsetX + "px";
      this.titleY = event.offsetY + "px";
      this.titleFloor = this.availableObj[floor] ? floor : null;
      this.titleActive = true;
    },
    hideTitle() {
      this.titleActive = false;
    },
    setFloor(floor, flat_id) {
      if (this.availableObj[floor]) {
        this.$store.state.floor = +floor;

        let first;
        if (flat_id)
          first = this.floors[floor].find((flat) => {
            return flat.id == flat_id && flat.status !== "sold" ? true : false;
          });

        if (!flat_id || !first)
          first = this.floors[floor].find((flat) => {
            return flat.status !== "sold" ? true : false;
          });
        this.setFlat(first ? first.id : 0);
      }
    },
    nextfloor(down) {
      if (!this.hasFloors) return;
      const floorIndex = this.availableFloors.indexOf(this.active + "");
      const nextIndex = floorIndex + (down ? -1 : 1);
      const nextFloor = this.availableFloors[nextIndex];
      if (nextFloor) this.setFloor(nextFloor);
    },
    onScroll(event) {
      if (event.deltaY) this.nextfloor(event.deltaY > 0);
    },
    onArrow(event) {
      if (event.keyCode == 40) {
        this.nextfloor(true);
      } else if (event.keyCode == 38) {
        this.nextfloor();
      }
    },
    setFlat(id) {
      this.$store.state.flat_id = id;
    },
    enterFlat(flat) {
      if (flat.status !== "sold") this.setFlat(flat.id);
    },
    onFloorLoad(floor) {
      this.loadedFloors[floor] = true;
      this.loaded += 1;
    }
  },
  created() {
    this.setFloor(
      this.availableObj[this.$store.state.floor]
        ? this.$store.state.floor
        : this.availableFloors[0],
      this.$store.state.flat_id
    );
  },
  mounted() {
    this.$nextTick(() => {
      if (
        this.availableFloors &&
        this.loaded >= (this.availableFloors.length ? 2 : 1)
      ) {
        this.$emit("loaded");
      } else {
        const readyLoop = setInterval(() => {
          if (
            this.availableFloors &&
            this.loaded >= (this.availableFloors.length ? 2 : 1)
          ) {
            clearInterval(readyLoop);
            this.$emit("loaded");
          }
        }, 200);
      }
    });
  }
};
</script>

<style lang="less">
.floors {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-grow: 1;
  height: 1px;
  width: 100%;
  padding: 200px 50px 50px 13vw;
  overflow: hidden;
  @media @bw1510 {
    padding: 135px 30px 30px 8vw;
  }
  @media @w1510bh750 {
    padding: 135px 30px 30px 8vw;
  }
  &::before {
    content: "";
    display: block;
    width: 31.5vw;
    min-width: 500px;
  }
  &__building {
    position: absolute;
    bottom: 0;
    left: 13vw;
    @media @bw1510 {
      left: 8vw;
    }
    @media @w1510bh750 {
      left: 8vw;
    }
  }
  &__img {
    display: block;
    width: 31.5vw;
    min-width: 500px;
    max-height: 95vh;
    object-fit: contain;
  }
  &__path-title {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    transform: translate(20px; -10px);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s ease;
    z-index: 5;
    &--active {
      opacity: 1;
    }
  }
  &__path-floor,
  &__path-sold {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @dark;
    color: @white;
    font-weight: 700;
    font-size: 11px;
    line-height: 1;
    white-space: nowrap;
  }
  &__path-floor {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
  &__path-sold {
    padding: 4px 6px;
    border-radius: 2px;
    text-transform: uppercase;
  }
  &__info-wrapper {
    box-sizing: border-box;
    flex-grow: 1;
    width: 1px;
  }
  &__svg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
  }
  &__floor-path {
    color: #433e37;
    opacity: 0.5;
    cursor: default;
    &--available {
      color: #cfb16e;
      cursor: pointer;
      opacity: 0;
    }
    &--active {
      color: #cfb16e;
      opacity: 0.6;
      cursor: default;
    }
    &:hover,
    &:active {
      opacity: 0.6;
    }
  }
  &__info {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 150px;
    display: flex;
    flex-direction: column;
    @media @bw1510 {
      padding-bottom: 120px;
    }
    @media @w1510bh750 {
      padding-bottom: 120px;
    }
  }
  &__scheme {
    flex-grow: 1;
    position: relative;
  }
  &__flat-img,
  &__flat-highlight,
  &__flat-captions {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    @media @bw1920 {
      max-width: 548px;
    }
  }
  &__flat-img {
    object-fit: contain;
  }
  &__flat-highlight,
  &__flat-captions {
    opacity: 0;
    &--active {
      opacity: 1;
    }
  }
  &__flat-highlight {
    pointer-events: auto;
  }
  &__flat-captions {
    text-transform: uppercase;
    font-family: @font1;
    font-weight: 700;
    font-size: 11px;
    line-height: 1.2;
    pointer-events: none;
  }
  &__flat-path {
    opacity: 0.15;
    cursor: pointer;
    transition: opacity 0.2s;
    &--active {
      opacity: 0.4;
    }
    // &--disabled {
    //   fill: #282d34;
    //   opacity: 0.35;
    //   cursor: default;
    //   &:hover,
    //   &:active {
    //     opacity: 0.55;
    //   }
    // }
    &--reserved {
      fill: @gold;
    }
    &--sold {
      pointer-events: none;
      fill: @red;
    }
    &--rent {
      fill: @blue;
    }
  }
  &__arrows {
    position: absolute;
    right: 8.5%;
    bottom: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }
  &__arrows-info {
    padding: 30px 0;
    text-align: center;
  }
  &__arrows-num {
    margin: 0 0 10px;
    font-family: @font2;
    font-weight: 700;
    font-size: 34px;
    line-height: 30px;
  }
  &__arrows-caption {
    color: @white_60;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
  &__specs {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 40px;
    @media @bw1510 {
      height: 120px;
      padding-bottom: 35px;
    }
    @media @w1510bh750 {
      height: 120px;
      padding-bottom: 35px;
    }
  }
  &__spec {
    position: relative;
    flex-shrink: 0;
    margin-right: 30px;
    @media @bw1340 {
      margin-right: 20px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__spec-data,
  &__spec-btn {
    margin-bottom: 6px;
    padding: 0;
    border: none;
    background: none;
    font-family: @font2;
    font-weight: 700;
    font-size: 46px;
    line-height: 0.9;
    text-transform: uppercase;
    @media @bw1510 {
      font-size: 36px;
    }
    @media @bw1340 {
      font-size: 28px;
    }
    @media @w1510bh750 {
      font-size: 36px;
    }
  }
  &__spec-btn {
    transition: color 0.2s;
    text-decoration: underline;
    &--disabled {
      text-decoration: none;
      pointer-events: none;
    }
    &:active {
      color: @gold;
    }
    &:hover {
      @media (hover: hover) {
        color: @gold;
        & + .floors__spec-prompt {
          opacity: 1;
        }
      }
    }
  }
  &__spec-prompt {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 120%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 5px 7px;
    text-align: center;
    border-radius: 7px;
    font-size: 12px;
    font-weight: 500;
    background-color: #646b72;
    opacity: 0;
    transition: opacity 0.2s;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -5px;
      margin: 0 auto;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      background-color: #646b72;
    }
  }
  &__spec-caption {
    color: @white_60;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
  &__specs-note {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
  }
  &__note-link {
    color: @gold;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    transition: text-decoration-color 0.2s;
    &:hover,
    &:active {
      text-decoration-color: transparent;
    }
  }
}
</style>
