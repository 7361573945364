<template>
  <main class="app__page improvement">
    <div class="improvement__container">
      <h1 class="improvement__title title title--bb">
        {{ $t("pages.improvement.title") }}
      </h1>
      <img
        class="improvement__main-img"
        src="/img/improvement/improvement1.jpg"
        alt="improvement1"
        @load="loaded += 1"
      />
    </div>

    <div v-if="pageLocale" class="improvement__content-wrapper">
      <div class="improvement__bg-img bg-img"></div>
      <div class="improvement__semicircles is-observable"></div>
      <div class="improvement__semicircles-right"></div>

      <div class="improvement__container">
        <div class="improvement__main-text-observer is-observable">
          <p class="improvement__main-text observe-target observe-80-bottom">
            {{ pageLocale.main_paragraph[0] }}
            <span class="gold-text">
              {{ pageLocale.main_paragraph[1] }}
            </span>
            {{ pageLocale.main_paragraph[2] }}
          </p>
        </div>

        <div class="improvement__infrastructure">
          <div class="improvement__infrastructure-box">
            <p
              class="
                improvement__infrastructure-text
                improvement__infrastructure-text--mb20
                is-observable
              "
            >
              <span class="d-block observe-target observe-40-left">
                {{ pageLocale.advantages[0] }}
              </span>
            </p>

            <div class="improvement__infrastructure-img-wrap is-observable">
              <img
                class="
                  improvement__infrastructure-img
                  observe-target observe-80-bottom
                "
                src="/img/improvement/improvement2.jpg"
                alt="improvement2"
              />
            </div>

            <p class="improvement__infrastructure-text is-observable">
              <span class="d-block observe-target observe-80-bottom">
                {{ pageLocale.advantages[1] }}
              </span>
            </p>
          </div>
        </div>
      </div>

      <div class="improvement__bg-wrap">
        <img
          class="improvement__bg-word"
          src="/img/improvement/infrastructure.svg"
          alt="infrastructure"
        />
      </div>

      <div class="improvement__container improvement__container--3">
        <div class="improvement__pattern is-observable"></div>

        <div class="improvement__info improvement__info--mb130">
          <div class="improvement__info-img3-wrap is-observable">
            <img
              class="improvement__info-img3 observe-target observe-80-bottom"
              src="/img/improvement/improvement3.jpg"
              alt="improvement3"
            />
          </div>

          <div class="improvement__info-item">
            <h3 class="improvement__info-title is-observable">
              <span class="d-block observe-target observe-40-right">
                {{ pageLocale.advantages[2] }}
              </span>
            </h3>
            <p
              class="
                improvement__info-text improvement__info-text--pr
                is-observable
              "
            >
              <span class="d-block observe-target observe-80-bottom">
                {{ pageLocale.advantages[3] }}
              </span>
            </p>
          </div>
        </div>
        <div class="improvement__info improvement__info--mb175">
          <p
            class="
              improvement__info-text improvement__info-text--w550
              is-observable
            "
          >
            <span
              class="d-block observe-target observe-80-bottom"
              v-html="pageLocale.advantages[4]"
            >
            </span>
          </p>
          <div class="improvement__img-wrap is-observable">
            <img
              class="improvement__info-img4 observe-target observe-80-bottom"
              src="/img/improvement/improvement4.jpg"
              alt="improvement4"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="improvement__container-2500">
      <localized-link to="/parking" class="next-page">
        <span class="next-page__capiton">{{ $t("next") }}</span>
        <span class="next-page__name">
          <span class="next-page__title">
            {{ $t("pages.parking.title") }}
          </span>
          <span class="next-page__btn r-btn">
            <svg-icon
              width="14"
              height="10"
              name="arrow_right"
              class="btn-icon"
            />
          </span>
        </span>
      </localized-link>
    </div>
    <PageFooter />
  </main>
</template>

<script>
import { pageLocale } from "@/mixins/pageLocale.js";
import { observer } from "@/mixins/observer.js";
import PageFooter from "@/components/PageFooter";
export default {
  name: "improvement",
  components: { PageFooter },
  mixins: [pageLocale, observer],
  data() {
    return {
      loadQty: 3 // смонтировано + перевод + 1 изображение
    };
  }
};
</script>

<style lang="less">
.improvement {
  .padding-page-top;
  position: relative;
  overflow: hidden;
  &__semicircles-right {
    position: absolute;
    top: 19%;
    right: 0;
    width: 185px;
    height: 336px;
    overflow: hidden;
    z-index: -1;
    @media @bw1680 {
      top: 17%;
      width: 131px;
      height: 293px;
    }
    @media @bw1170 {
      width: 107px;
      height: 213px;
    }
    @media @bw768 {
      top: 12%;
      width: 31px;
      height: 104px;
    }
    @media @bw400 {
      top: 9%;
      width: 28px;
      height: 101px;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 307px;
      height: 305px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid @white;
      @media @bw1680 {
        width: 275px;
        height: 275px;
      }
      @media @bw1170 {
        width: 196px;
        height: 195px;
      }
      @media @bw768 {
        width: 118px;
        height: 118px;
      }
    }
    &::before {
      top: 27px;
      left: 30px;
      @media @bw1680 {
        top: 22px;
        left: 33px;
      }
      @media @bw1170 {
        top: 16px;
        left: 25px;
      }
      @media @bw768 {
        top: 1px;
        left: 17px;
      }
    }
    &::after {
      top: 0;
      left: 1px;
      border: 1px solid @gold;
      @media @bw768 {
        top: -9px;
      }
    }
  }
  &__container {
    .container;
    &--3 {
      display: flex;
      flex-direction: column;
      position: relative;
    }
  }
  &__pattern {
    position: absolute;
    left: -44px;
    top: 220px;
    width: 95px;
    height: 321px;
    @media @bw1510 {
      left: 0px;
      top: 305px;
      width: 30px;
      height: 270px;
    }
    @media @bw1170 {
      top: 265px;
      width: 20px;
      height: 62px;
    }
    @media @bw768 {
      display: none;
    }
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: @gold;
      transform-origin: 0 50%;
    }
    &.is-observable {
      &::before {
        transform: scaleX(0);
      }
    }
    &.is-observed {
      &::before {
        transition: transform 1.5s ease-out;
        transform: scaleX(1);
      }
    }
  }
  &__title {
    white-space: pre-wrap;
  }
  &__main-img {
    margin-bottom: 140px;
    width: 100%;
    box-shadow: 0px 50px 100px rgba(51, 57, 66, 0.6);
    @media @bw1510 {
      margin-bottom: 120px;
    }
    @media @w1510bh750 {
      margin-bottom: 120px;
    }
    @media @bw1170 {
      margin-bottom: 100px;
    }
    @media @bw768 {
      margin-bottom: 60px;
    }
    @media @bw400 {
      margin-bottom: 50px;
    }
  }
  &__main-text {
    margin: 0;
  }
  &__main-text-observer {
    position: relative;
    margin: 0 auto 95px;
    max-width: 935px;
    width: 100%;
    text-align: left;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.2;
    @media @bw1510 {
      margin-bottom: 80px;
      margin-left: 35px;
      max-width: 892px;
    }
    @media @w1510bh750 {
      margin-bottom: 80px;
      margin-right: 35px;
      max-width: 892px;
    }
    @media @bw1170 {
      box-sizing: border-box;
      margin-left: 0;
      margin-bottom: 50px;
      padding-right: 120px;
      max-width: 100%;
      font-size: 24px;
    }
    @media @bw768 {
      padding-right: 0;
      font-weight: 500;
      font-size: 20px;
    }
    @media @bw400 {
      margin-bottom: 40px;
      font-size: 18px;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      right: -350px;
      bottom: 0;
      width: 76px;
      height: 95px;
      background-color: @gold;
      transform-origin: 100% 50%;
      @media @bw1860 {
        bottom: -90px;
        right: -50px;
        width: 60px;
        height: 70px;
      }
      @media @bw1510 {
        right: -105px;
        bottom: -63px;
      }
      @media @bw1170 {
        right: 0;
        bottom: -20%;
        width: 40px;
        height: 51px;
      }
      @media @bw768 {
        right: -20px;
        bottom: -26px;
        width: 14px;
        height: 52px;
      }
      @media @bw400 {
        bottom: 0;
      }
    }
    &.is-observable {
      &::after {
        transform: scaleX(0);
      }
    }
    &.is-observed {
      &::after {
        transition: transform 1s 0.5s ease-out;
        transform: scaleX(1);
      }
    }
  }
  &__infrastructure {
    position: relative;
  }
  &__bg-wrap {
    position: relative;
    margin-bottom: 100px;
    z-index: -1;
    pointer-events: none;
    @media @bw1510 {
      margin-bottom: 95px;
    }
    @media @bw1170 {
      margin-bottom: 50px;
    }
    @media @bw768 {
      margin-bottom: 40px;
    }
    &::before {
      display: block;
      width: 100%;
      height: 0;
      padding-top: 14.671%;
      @media @bw1170 {
        padding-top: 14.666%;
      }
      @media @bw768 {
        padding-top: 14.671%;
      }
    }
  }
  &__semicircles {
    position: absolute;
    top: 43%;
    left: 0;
    width: 300px;
    height: 403px;
    // overflow: hidden;
    @media @bw1510 {
      width: 95px;
      height: 297px;
    }
    @media @bw1170 {
      display: none;
    }
    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 380px;
      height: 380px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid @white;
      @media @bw1510 {
        width: 294px;
        height: 293px;
      }
    }
    &::before {
      top: 20px;
      right: 0;
      @media @bw1510 {
        top: 0;
      }
    }
    &::after {
      top: 0;
      right: 60px;
      border: 1px solid @gold;
      @media @bw1510 {
        right: 45px;
        top: -18px;
      }
    }
    &.is-observable {
      &::before,
      &::after {
        transform: scale(0.5);
      }
    }
    &.is-observed {
      &::before,
      &::after {
        transform: scale(0.5);
        animation-name: bounceIn;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
      }
      &::before {
        animation-duration: 2.6s;
      }
      &::after {
        animation-duration: 2.4s;
        animation-delay: 0.2s;
      }
    }
  }
  &__infrastructure-box {
    box-sizing: border-box;
    position: relative;
    margin-right: 0;
    margin-left: auto;
    padding-right: 614px;
    padding-bottom: 30px;
    max-width: 1000px;
    width: 100%;
    @media @bw1510 {
      margin-right: auto;
      padding-right: 436px;
      padding-bottom: 26px;
      max-width: 812px;
    }
    @media @bw1340 {
      margin-right: 0px;
    }
    @media @bw1170 {
      max-width: 100%;
      padding-right: 364px;
      padding-left: 40px;
    }
    @media @bw768 {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      padding-right: 0;
      padding-bottom: 0;
      padding-left: 0;
      max-width: 100%;
    }
  }
  &__infrastructure-img-wrap {
    position: absolute;
    right: 0;
    bottom: -26%;
    width: 447px;
    max-width: 100%;
    height: 334px;
    box-shadow: 0px 50px 50px rgba(51, 57, 66, 0.6);
    @media @bw1510 {
      bottom: -6%;
      width: 361px;
      height: 252px;
    }
    @media @bw1170 {
      bottom: 19%;
      width: 313px;
      height: 218px;
    }
    @media @bw768 {
      margin-bottom: 40px;
      position: static;
      width: 375px;
      height: auto;
    }
  }
  &__infrastructure-text {
    box-sizing: border-box;
    margin: 0;
    max-width: 386px;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: @white_80;
    @media @bw1170 {
      max-width: 100%;
    }
    @media @bw768 {
      padding-left: 70px;
      align-self: flex-end;
    }
    @media @bw400 {
      padding-left: 15px;
    }
    &--mb20 {
      margin-bottom: 20px;
      @media @bw768 {
        margin-bottom: 40px;
      }
    }
  }
  &__info {
    align-self: flex-end;
    display: flex;
    align-items: center;
    @media @bw1510 {
      align-self: center;
    }
    @media @bw768 {
      flex-direction: column;
      align-self: flex-start;
    }
    &--mb130 {
      margin-bottom: 130px;
      align-items: flex-start;
      @media @bw1170 {
        margin-bottom: 120px;
      }
      @media @bw768 {
        margin-bottom: 40px;
      }
    }
    &--mb175 {
      margin-bottom: 175px;
      @media @bw1510 {
        margin-bottom: 170px;
      }
      @media @bw1170 {
        margin-bottom: 120px;
      }
      @media @bw768 {
        margin-bottom: 100px;
      }
    }
  }
  &__info-img3-wrap {
    margin-right: 70px;
    max-width: 568px;
    width: 100%;
    box-shadow: 0px 50px 50px rgba(51, 57, 66, 0.6);
    @media @bw1510 {
      max-width: 453px;
    }
    @media @bw1170 {
      margin-right: 55px;
      max-width: 342px;
    }
    @media @bw768 {
      margin-right: 0;
      margin-bottom: 50px;
    }
  }
  &__info-title {
    margin: 0 0 40px;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.2;
    @media @bw1170 {
      font-size: 24px;
    }
    @media @bw768 {
      font-size: 20px;
    }
    @media @bw400 {
      font-size: 18px;
    }
  }
  &__info-item {
    max-width: 470px;
    width: 100%;
    @media @bw1510 {
      max-width: 450px;
    }
    @media @bw1170 {
      max-width: 313px;
    }
    @media @bw768 {
      max-width: 100%;
    }
  }
  &__info-text {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    color: @white_80;
    &--pr {
      padding-right: 60px;
      @media @bw1510 {
        padding-right: 40px;
      }
      @media @bw1170 {
        padding-right: 0;
      }
    }
    &--w550 {
      position: relative;
      margin-right: 165px;
      max-width: 550px;
      width: 100%;
      @media @bw1510 {
        margin-right: 90px;
      }
      @media @bw1170 {
        margin-right: 75px;
      }
      @media @bw768 {
        order: 2;
        margin-right: 0;
        align-self: flex-end;
        max-width: 100%;
        padding-left: 70px;
        box-sizing: border-box;
      }
      @media @bw400 {
        max-width: 100%;
        padding-left: 0px;
      }
      &::before {
        display: none;
        @media @bw768 {
          display: block;
          content: "";
          position: absolute;
          top: -20px;
          left: -20px;
          width: 20px;
          height: 62px;
          background-color: @gold;
        }
        @media @bw400 {
          top: -26px;
          width: 7px;
          height: 52px;
        }
      }
    }
  }
  &__img-wrap {
    box-sizing: border-box;
    position: relative;
    margin-right: 125px;
    width: 338px;
    height: 224px;
    @media @bw1510 {
      margin-right: 0px;
      width: 282px;
      height: 186px;
    }
    @media @bw1170 {
      width: 255px;
      height: 168px;
    }
    @media @bw768 {
      align-self: flex-end;
      margin-top: 0px;
      margin-bottom: 40px;
      padding-right: 32px;
      padding-bottom: 24px;
      order: 1;
      width: 372px;
      height: 249px;
    }
    @media @bw400 {
      padding-bottom: 18px;
      padding-right: 24px;
      width: 100%;
      height: auto;
    }
    &::before {
      content: "";
      position: absolute;
      top: 24px;
      left: 32px;
      right: -32px;
      bottom: -24px;
      background-color: transparent;
      border: 1px solid @gold;
      z-index: -1;
      @media @bw1510 {
        top: 20px;
        left: 26px;
        right: -26px;
        bottom: -20px;
      }
      @media @bw768 {
        top: 20px;
        left: 26px;
        right: 0px;
        bottom: 0px;
      }
    }
  }
  &__info-img4 {
    box-shadow: 0px 50px 50px rgba(51, 57, 66, 0.6);
  }
  &__container-2500 {
    .container-2500;
  }
}
</style>
