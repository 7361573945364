<template>
  <main
    class="app__page home"
    v-keyup="onArrow"
    @wheel="onScroll($event)"
    v-hammer:swipe="swipe"
  >
    <div class="home__wrapper">
      <div class="home__background" :class="'home__background--' + active">
        <img
          src="@/assets/images/main-bg.jpg"
          alt="bg"
          class="home__bg"
          @load="loaded += 1"
        />
        <img
          src="@/assets/images/main.png"
          alt="building"
          class="home__building"
          @load="loaded += 1"
        />
        <img
          src="@/assets/images/main-clouds.png"
          alt="clouds"
          class="home__clouds"
          @load="loaded += 1"
        />
      </div>

      <div class="home__bg-logo"></div>

      <div class="home__main">
        <div class="home__bg-text">Modern</div>
        <div class="home__price" :class="{ 'home__price--active': per_sqr }">
          <div class="home__price-wrapper">
            <h4 class="home__price-title">
              {{ $t("pages.index.price") }}
            </h4>
            <p class="home__price-value">
              <template v-if="price_on_request">
                {{ $t("requestPrice") }}
              </template>

              <template v-else>
                {{ $t("pages.index.from") }}
                {{ per_sqr }} €
              </template>
            </p>
            <p class="home__price-caption">
              {{ $t("price_note") }}
            </p>
            <button
              type="button"
              class="home__price-link"
              @click="openCallMe()"
            ></button>
          </div>
        </div>

        <div class="home__slides">
          <section
            class="home__slide"
            :class="{ 'home__slide--active': active === 0 }"
          >
            <h1 class="home__title">
              <span v-if="$te('pages.index.title_0[0]')">
                <span>
                  {{ $t("pages.index.title_0[0]") }}
                </span>
              </span>
              <span class="home__title-gold">
                <span class="home__title-gold-text">
                  {{ $t("pages.index.title_0[1]") }}
                </span>
              </span>
              <span
                v-if="$te('pages.index.title_0[2]')"
                class="home__title-adress"
              >
                <span>
                  {{ $t("pages.index.title_0[2]") }}
                </span>
              </span>
            </h1>

            <localized-link
              to="/flats"
              class="home__btn btn"
              :data-plank="$t('pages.index.choose')"
            >
              {{ $t("pages.index.choose") }}
            </localized-link>
          </section>

          <section
            class="home__slide"
            :class="{ 'home__slide--active': active === 1 }"
          >
            <h2 class="home__title home__title--1">
              <span
                v-if="$te('pages.index.title_1[0]')"
                class="home__title-gold"
              >
                <span class="home__title-gold-text">
                  {{ $t("pages.index.title_1[0]") }}
                </span>
              </span>
              <span>
                {{ $t("pages.index.title_1[1]") }}
              </span>
              <span
                v-if="$te('pages.index.title_1[2]')"
                class="home__title-gold"
              >
                <span class="home__title-gold-text">
                  {{ $t("pages.index.title_1[2]") }}
                </span>
              </span>
            </h2>

            <localized-link
              to="/architecture"
              class="home__btn btn"
              :data-plank="$t('nav.architecture')"
            >
              {{ $t("nav.architecture") }}
            </localized-link>
          </section>

          <section
            class="home__slide"
            :class="{ 'home__slide--active': active === 2 }"
          >
            <h2 class="home__title home__title--2">
              {{ $t("pages.index.title_2") }}
            </h2>

            <localized-link
              to="/improvement"
              class="home__btn btn"
              :data-plank="$t('nav.improvement')"
            >
              {{ $t("nav.improvement") }}
            </localized-link>
          </section>
        </div>
      </div>

      <footer class="home__footer">
        <div class="home__copyrights">
          {{ `©${new Date().getFullYear()} Panorama` }}
        </div>

        <div class="home__slider-elements">
          <div class="home__progress">
            <div class="home__progress-num">0{{ active + 1 }}</div>
            <div
              class="home__progress-line"
              :class="'home__progress-line--' + active"
            ></div>
            <div class="home__progress-num">03</div>
          </div>

          <div class="home__arrows">
            <button
              type="button"
              class="home__arrow r-btn"
              :class="{ 'home__arrow--hidden': active === 0 }"
              @click="nextSlide()"
            >
              <svg-icon
                width="12"
                height="14"
                name="arrow_up"
                class="btn-icon"
              />
            </button>

            <button
              type="button"
              class="home__arrow r-btn"
              :class="{ 'home__arrow--disabled': active === 2 }"
              @click="nextSlide(true)"
            >
              <svg-icon
                width="12"
                height="14"
                name="arrow_down"
                class="btn-icon"
              />
            </button>
          </div>
        </div>

        <div class="home__footer-links">
          <localized-link to="/documentation" class="home__footer-link">
            {{ $t("nav.documentation") }}
          </localized-link>

          <localized-link to="/cookies" class="home__footer-link">
            {{ $t("nav.cookies") }}
          </localized-link>
          <localized-link to="/privacy" class="home__footer-link">
            {{ $t("nav.privacy") }}
          </localized-link>
        </div>
      </footer>
    </div>
  </main>
</template>

<script>
import { pageLoaded } from "@/mixins/pageLoaded.js";

export default {
  name: "index",
  mixins: [pageLoaded],
  data() {
    return {
      active: 0,
      isAnimaton: false,
      loadQty: 4 // смонтировано + 3 изображения
    };
  },
  computed: {
    per_sqr() {
      const per_sqr = this.$store.state.per_sqr;
      return per_sqr ? this.$global.numberSpaces(per_sqr) : "";
    },
    price_on_request() {
      return this.$store.state.price_on_request;
    }
  },
  methods: {
    openCallMe() {
      this.$store.state.callMeActive = true;
    },
    onScroll(event) {
      if (this.isAnimaton) return;
      if (event.deltaY) this.nextSlide(event.deltaY > 0);
    },
    onArrow(event) {
      if (this.isAnimaton) return;
      if (event.keyCode == 40) {
        this.nextSlide(true);
      } else if (event.keyCode == 38) {
        this.nextSlide();
      }
    },
    nextSlide(down) {
      if (this.isAnimaton) return;
      if (down && this.active < 2) {
        this.isAnimaton = true;
        this.active = this.active + 1;
      } else if (!down && this.active > 0) {
        this.isAnimaton = true;
        this.active = this.active - 1;
      }
      setTimeout(() => {
        this.isAnimaton = false;
      }, 1500);
    },
    swipe(event) {
      if (event.direction == 16) {
        this.nextSlide();
      } else if (event.direction == 8) {
        this.nextSlide(true);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.loaded = this.loaded + 1;
    });
  }
};
</script>

<style lang="less">
.home {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  &__wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 240px 50px 50px;
    overflow: hidden;
    z-index: 0;
    @media @bw1510 {
      padding: 150px 30px 30px;
    }
    @media @bw1020 {
      padding: 135px 30px 30px;
    }
    @media @bw768 {
      padding: 115px 20px 20px;
    }
    @media @bw400 {
      padding: 100px 20px 20px;
    }
    @media @w1510bh750 {
      padding: 150px 30px 30px;
    }
    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 7;
    }
    &::before {
      top: 0;
      height: 240px;
      background-image: linear-gradient(180deg, fade(@bg, 50%), transparent);
      @media @bw1510 {
        height: 150px;
      }
      @media @bw1020 {
        height: 135px;
      }
      @media @bw768 {
        height: 115px;
      }
      @media @bw400 {
        height: 100px;
      }
      @media @w1510bh750 {
        height: 150px;
      }
    }
    &::after {
      bottom: 0;
      height: 170px;
      background-image: linear-gradient(0deg, fade(@bg, 50%), transparent 100%);
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    pointer-events: none;
    z-index: 1;
    &--0 {
      & .home__bg {
        transform: translate3d(0, -5%, 0);
        @media @bw600 {
          transform: translate3d(0, -10%, 0);
        }
      }
      & .home__building {
        transform: translate3d(0, 0, 0);
        @media @bw600 {
          transform: translate3d(0, -5%, 0);
        }
      }
      & .home__clouds {
        transform: translate3d(0, 0%, 0);
        @media @bw1170 {
          transform: translate3d(0, -3%, 0);
        }
        @media @bw600 {
          transform: translate3d(0, -4%, 0);
        }
      }
    }
    &--1 {
      & .home__bg {
        transform: translate3d(0, -33.33%, 0);
      }
      & .home__building {
        transform: translate3d(0, -33.33%, 0);
      }
      & .home__clouds {
        transform: translate3d(0, -42%, 0);
      }
    }
    &--2 {
      & .home__bg {
        transform: translate3d(0, -66.66%, 0);
      }
      & .home__building {
        transform: translate3d(0, -66.66%, 0);
      }
      & .home__clouds {
        transform: translate3d(0, -80%, 0);
      }
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: fade(@bg, 20%);
    }
  }
  &__bg,
  &__building,
  &__clouds {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300%;
    max-width: none;
    max-height: none;
    transition: transform 1.5s ease;
    will-change: transform;
    backface-visibility: hidden;
    object-fit: cover;
    @media @bw1170 {
      object-position: 15% 50%;
    }
    @media @bw1020 {
      object-position: 28% 50%;
    }
    @media @bw600 {
      object-position: 28% 50%;
    }
  }
  &__bg-logo {
    position: absolute;
    top: -330px;
    right: 133px;
    width: 55.872916%;
    background-image: url("~@/assets/images/bg_logo.svg");
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.12;
    z-index: 2;
    @media @bw1920 {
      width: 1067px;
      height: 1823px;
    }
    @media @bw1340 {
      top: -158px;
      right: 70px;
      width: 642px;
      height: 1096px;
    }
    @media @bw1170 {
      display: none;
    }
    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 170.8528%;
      @media @bw1920 {
        display: none;
      }
    }
  }
  &__main {
    box-sizing: border-box;
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-start;
    margin: auto 0 20px;
    @media @bw1170 {
      flex-direction: column;
      margin: auto 0 0;
    }
    @media @bw1170bh480 {
      flex-grow: 1;
      margin: 0;
    }
  }
  &__bg-text {
    .no-events;
    position: absolute;
    top: -11vw;
    left: 50%;
    transform: translate(-50%, 0);
    color: transparent;
    text-stroke: 1px @white_20;
    -webkit-text-stroke: 1px @white_20;
    font-family: @font2;
    font-weight: 700;
    font-size: 40vw;
    line-height: 1;
    text-transform: uppercase;
    z-index: 3;
    @media @bw1920 {
      font-size: 800px;
      margin-top: -40px;
    }
    @media @bw1680 {
      margin-top: 0;
    }
  }
  &__price {
    flex-shrink: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 120px;
    margin-right: 125px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all linear 0.2s;
    z-index: 10;
    &--active {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
    @media @bw1510 {
      margin-right: 70px;
    }
    @media @bw1170 {
      width: auto;
      margin-right: 0;
      margin-bottom: 50px;
    }
    @media @bw768 {
      margin-bottom: 40px;
    }
    @media @bw400 {
      margin-bottom: 35px;
    }
    @media @w1020bh750 {
      margin-bottom: 40px;
    }
    @media @w1020bh550 {
      margin-bottom: 35px;
    }
    @media @bw1170w600bh750 {
      display: none;
    }
    @media @bw1170bh550 {
      display: none;
    }
  }
  &__price-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &:hover,
    &:active {
      .home__price-caption {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__price-title {
    .no-events;
    margin: 0 0 5px;
    color: @white_60;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
  }
  &__price-value {
    .no-events;
    margin: 0;
    padding: 4px 4px 2px 4px;
    font-family: @font2;
    font-size: 22px;
    line-height: 20px;
    background-color: @dark;
    white-space: nowrap;
  }
  &__price-caption {
    display: block;
    position: absolute;
    box-sizing: border-box;
    margin: 0;
    margin-left: -1px;
    margin-bottom: -1px;
    left: 100%;
    bottom: 100%;
    padding: 4px 6px;
    box-shadow: 4px 4px 4px fade(@dark, 15%);
    background-color: @gold;
    border-radius: 2px;
    color: @dark;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: all 0.15s linear;
    z-index: 10;
    @media @bw768 {
      display: none;
    }
  }
  &__price-link {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    background: none;
    font-size: 0;
    text-decoration: 0;
    z-index: 10;
  }
  &__slides {
    flex-grow: 1;
    position: relative;
    width: 100%;
    user-select: none;
    z-index: 10;
  }
  &__slide {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    pointer-events: none;
    user-select: none;
    @media @bw1170bh480 {
      height: 100%;
    }
    &--active {
      pointer-events: auto;
      z-index: 1;
      & .home__title {
        filter: blur(0px);
        opacity: 1;
      }
      & .home__btn {
        &::before {
          transform: rotateX(0deg);
        }
        &::after {
          opacity: 1;
        }
      }
    }
    &:nth-child(n + 2) {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }
  &__title {
    .no-events;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 300px;
    margin: 0 0 45px;
    font-family: @font2;
    font-weight: 700;
    font-size: 100px;
    line-height: 100px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    white-space: pre-wrap;
    filter: blur(20px);
    opacity: 0.01;
    transition: filter 1.1s ease-in-out, opacity 1.1s ease-in-out;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    will-change: filter, opacity;
    @media @bw1680 {
      height: 200px;
      font-size: 90px;
      line-height: 84px;
    }
    @media @bw1510 {
      height: 185px;
      font-size: 90px;
      line-height: 75px;
    }
    @media @bw1170 {
      margin: 0 0 55px;
    }
    @media @bw1170bh480 {
      margin: auto 0 !important;
    }
    @media @bw1020 {
      margin: 0 0 45px;
      height: 275px;
      max-width: 550px;
      font-size: 85px;
      line-height: 75px;
    }
    @media @bw1020l {
      margin: 0 0 45px;
      max-width: 100%;
      height: 90px;
      font-size: 44px;
      line-height: 36px;
    }
    @media @bw768 {
      margin: 0 0 45px;
      max-width: 450px;
      height: 220px;
      font-size: 72px;
      line-height: 60px;
    }
    @media @bw768l {
      margin: 0 0 40px;
      max-width: 100%;
      height: 85px;
      font-size: 40px;
      line-height: 33px;
    }
    @media @bw600 {
      margin: 0 0 45px;
      max-width: 370px;
      height: 190px;
      font-size: 55px;
      line-height: 50px;
    }
    @media @bw600l {
      margin: 0 0 25px;
      max-width: 100%;
      height: 70px;
      font-size: 30px;
      line-height: 25px;
    }
    @media @bw400 {
      margin: 0 0 40px;
      max-width: 330px;
      height: 180px;
      font-size: 50px;
      line-height: 45px;
    }
    @media @bw400l {
      margin: 0 0 25px;
      max-width: 100%;
      height: 68px;
      font-size: 30px;
      line-height: 25px;
    }
    @media @bw370 {
      margin: 0 0 40px;
      max-width: 270px;
      height: 144px;
      font-size: 44px;
      line-height: 36px;
    }
    @media @bw370l {
      margin: 0 0 25px;
      max-width: 100%;
      height: 50px;
      font-size: 30px;
      line-height: 25px;
    }
    @media @w1020bh750 {
      margin: 0 0 45px;
      height: 170px;
      font-size: 72px;
      line-height: 60px;
    }
    @media @w1020bh550 {
      margin: 0 0 40px;
      height: 140px;
      font-size: 60px;
      line-height: 50px;
    }
  }
  &__title-gold {
    .no-events;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    backface-visibility: hidden;
    &::before {
      .no-events;
      content: "";
      display: block;
      position: absolute;
      top: -5px;
      bottom: 8px;
      left: -4px;
      right: -10px;
      background-color: @gold;
      pointer-events: none;
      backface-visibility: hidden;
      @media @bw1680 {
        top: -4px;
        bottom: 5px;
        left: -4px;
        right: -10px;
      }
      @media @bw1510 {
        top: -4px;
        bottom: 5px;
        left: -4px;
        right: -8px;
      }
      @media @bw1020 {
        top: -4px;
        bottom: 5px;
        left: -4px;
        right: -8px;
      }
      @media @bw1020l {
        top: -2px;
        bottom: 3px;
        left: -2px;
        right: -4px;
      }
      @media @bw768 {
        top: -4px;
        bottom: 5px;
        left: -4px;
        right: -8px;
      }
      @media @bw768l {
        top: -2px;
        bottom: 3px;
        left: -2px;
        right: -4px;
      }
      @media @bw600 {
        top: -2px;
        bottom: 4px;
        left: -2px;
        right: -6px;
      }
      @media @bw600l {
        top: -2px;
        bottom: 2px;
        left: -2px;
        right: -3px;
      }

      @media @bw400 {
        top: -2px;
        bottom: 3px;
        left: -2px;
        right: -6px;
      }
      @media @bw400l {
        top: -2px;
        bottom: 2px;
        left: -2px;
        right: -2px;
      }

      @media @bw370 {
        top: -2px;
        bottom: 3px;
        left: -2px;
        right: -4px;
      }
      @media @bw370l {
        top: -2px;
        bottom: 2px;
        left: -2px;
        right: -2px;
      }
      @media @w1020bh750 {
        top: -4px;
        bottom: 5px;
        left: -4px;
        right: -8px;
      }
      @media @w1020bh550 {
        top: -3px;
        bottom: 4px;
        left: -3px;
        right: -6px;
      }
    }
  }
  &__title-adress {
    font-size: 65px;
    line-height: 1;
    letter-spacing: 0em;
    white-space: normal;
    @media @bw1680 {
      font-size: 60px;
    }
    @media @bw1510 {
      font-size: 50px;
    }
    @media @bw1020l {
      font-size: 20px;
    }
    @media @bw768 {
      font-size: 40px;
    }
    @media @bw768l {
      font-size: 20px;
    }
    @media @bw600 {
      font-size: 33px;
    }
    @media @bw600l {
      font-size: 20px;
    }
    @media @bw400 {
      font-size: 28px;
    }
    @media @bw400l {
      font-size: 18px;
    }
    @media @bw370 {
      font-size: 22px;
    }
    @media @bw370l {
      font-size: 18px;
    }
    @media @w1020bh550 {
      font-size: 40px;
    }
  }
  &__title-gold-text {
    position: relative;
    z-index: 1;
  }
  &__btn {
    width: 300px;
    max-width: 100%;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    @media @bw1170bh480 {
      margin-top: 20px;
      width: 280px;
    }
    @media @bw1020l {
      height: 66px;
    }
    @media @bw450 {
      width: 100%;
    }
    @media @bw400 {
      height: 66px;
    }
    @media @bw450bh480 {
      display: none;
    }
    &::before {
      backface-visibility: hidden;
      transform: rotateX(-180deg);
      transition: transform 1.5s ease;
      will-change: transform;
    }
    &::after {
      opacity: 0;
    }
  }
  &__footer {
    position: relative;
    flex-shrink: 0;
    margin-top: auto;
    display: flex;
    align-items: flex-end;
    color: @white;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    z-index: 15;
    @media @bw1170 {
      flex-wrap: wrap;
    }
  }
  &__footer-links {
    margin-left: auto;
    @media @bw1170w600bh550 {
      display: none;
    }
    @media @bw600 {
      display: none;
    }
  }
  &__footer-link {
    margin-left: 30px;
    color: @white;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    transition: color 0.2s;
    &:hover,
    &:active {
      color: @gold;
    }
  }
  &__copyrights {
    margin-right: 150px;
    @media @bw1510 {
      margin-right: 95px;
    }
    @media @bw1170 {
      margin-right: 20px;
    }
    @media @bw1170w600bh550 {
      display: none;
    }
    @media @bw600 {
      display: none;
    }
  }
  &__slider-elements {
    @media @bw1170 {
      order: -1;
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0 0 50px;
    }
    @media @bw600 {
      margin: 0;
    }
    @media @bw1170w600bh750 {
      margin: 0 0 25px;
    }
    @media @bw1170w600bh550 {
      margin: 0;
    }
  }
  &__progress {
    display: flex;
    align-items: center;
    @media @bw1170bh480 {
      display: none;
    }
    @media @bw450bh480 {
      display: flex;
    }
  }
  &__progress-num {
    display: flex;
    width: 27px;
    color: @white;
    font-family: @font2;
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
    &:last-child {
      justify-content: flex-end;
      color: @white_60;
      text-align: right;
    }
  }
  &__progress-line {
    position: relative;
    width: 100px;
    height: 2px;
    background-color: @white_60;
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: @gold;
      transition: transform 1s;
      transform: scaleX(0);
      transform-origin: 0 50%;
    }
    &--0 {
      &::after {
        transform: scaleX(0.34);
      }
    }
    &--1 {
      &::after {
        transform: scaleX(0.67);
      }
    }
    &--2 {
      &::after {
        transform: scaleX(1);
      }
    }
  }
  &__arrows {
    flex-shrink: 0;
    position: absolute;
    bottom: 0;
    right: 50%;
    display: flex;
    justify-content: flex-end;
    margin-right: -26px;
    @media @bw1170 {
      position: static;
      right: auto;
      margin-right: 0;
      margin-left: auto;
    }
    @media @bw1170bh480 {
      position: absolute;
      right: 0;
      bottom: 0;
    }
    @media @bw450bh480 {
      position: static;
    }
  }
  &__arrow {
    margin-left: 10px;
    transition: opacity 1s;
    &:first-child {
      margin-left: 0;
    }
    &--hidden {
      opacity: 0;
      pointer-events: none;
    }
    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
</style>
