<template>
  <main class="app__page parking">
    <div class="parking__container">
      <h1 class="parking__title title title--bb">
        {{ $t("pages.parking.title") }}
      </h1>
    </div>

    <div class="parking__main-img-wrap">
      <img
        class="parking__main-img"
        src="/img/parking/parking1.jpg"
        alt="parking1"
        @load="loaded += 1"
      />
      <div class="parking__semicircles"></div>
      <div class="parking__pattern is-observable"></div>
    </div>

    <div v-if="pageLocale" class="parking__content-wrapper">
      <div class="parking__bg-img bg-img"></div>
      <div class="parking__rectangle is-observable"></div>
      <div class="parking__semicircles-right is-observable"></div>

      <div class="parking__main-wrapper">
        <div class="parking__main">
          <!-- <h2 class="parking__main-subtitle">
            {{ pageLocale.parking.subtitle }}
          </h2> -->
          <p class="parking__main-text is-observable">
            <span class="d-block observe-target observe-80-bottom">
              {{ pageLocale.parking.text }}
            </span>
          </p>

          <img
            class="parking__main-img2"
            src="/img/parking/parking2.jpg"
            alt="parking2"
          />
          <img
            class="parking__main-bgimg"
            src="/img/parking/pantries.svg"
            alt="pantries"
          />
        </div>
        <div class="parking__advant">
          <div class="parking__advant-inner">
            <div class="parking__advant-info">
              <!-- <h2 class="parking__advant-subtitle">
                {{ pageLocale.pantries.subtitle }}
              </h2> -->
              <div class="parking__advant-texts is-observable">
                <p
                  class="parking__advant-text observe-target observe-80-bottom"
                >
                  {{ pageLocale.pantries.text[0] }}
                </p>
                <p
                  class="
                    parking__advant-text
                    observe-target observe-80-bottom
                    delay-02
                  "
                >
                  {{ pageLocale.pantries.text[1] }}
                </p>
              </div>
            </div>
            <div class="parking__advant-items">
              <div class="parking__security">
                <div class="parking__security-item">
                  <svg-icon
                    width="18"
                    height="18"
                    name="security"
                    class="parking__item-icon"
                  />
                </div>
                <div class="parking__security-caption">
                  <span class="parking__security-word"
                    >{{ pageLocale.advantages.security[0] }}
                  </span>
                  <span class="parking__security-word">{{
                    pageLocale.advantages.security[1]
                  }}</span>
                </div>
              </div>
              <div class="parking__cctv">
                <div class="parking__security-item">
                  <svg-icon
                    width="18"
                    height="18"
                    name="video"
                    class="parking__item-icon"
                  />
                </div>
                <div class="parking__security-caption">
                  <span class="parking__security-word"
                    >{{ pageLocale.advantages.CCTV[0] }}
                  </span>
                  <span class="parking__security-word">{{
                    pageLocale.advantages.CCTV[1]
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="parking__line"></div>
        <!-- <div class="parking__info-wrap">
          <div class="parking__info">
            <p class="parking__info-text1 is-observable">
              <span class="d-block observe-target observe-80-bottom">
                {{ pageLocale.info[0] }}
              </span>
            </p>
            <p class="parking__info-text2 is-observable">
              <span class="d-block observe-target observe-80-bottom">
                {{ pageLocale.info[1] }}
              </span>
            </p>
            <img
              class="parking__info-bgimg"
              src="/img/parking/parking.svg"
              alt="parking"
            />
          </div>
        </div> -->
      </div>
    </div>

    <div class="parking__container-2500">
      <localized-link to="/location" class="next-page">
        <span class="next-page__capiton">{{ $t("next") }}</span>
        <span class="next-page__name">
          <span class="next-page__title">
            {{ $t("pages.location.title") }}
          </span>
          <span class="next-page__btn r-btn">
            <svg-icon
              width="14"
              height="10"
              name="arrow_right"
              class="btn-icon"
            />
          </span>
        </span>
      </localized-link>
    </div>
    <PageFooter />
  </main>
</template>

<script>
import { pageLocale } from "@/mixins/pageLocale.js";
import { observer } from "@/mixins/observer.js";
import PageFooter from "@/components/PageFooter";
export default {
  name: "parking",
  components: { PageFooter },
  mixins: [pageLocale, observer],
  data() {
    return {
      loadQty: 3 // смонтировано + перевод + 1 изображение
    };
  }
};
</script>

<style lang="less">
@keyframes parking-line {
  from {
    background-position: 0 50%;
  }
  to {
    background-position: -166px 50%;
  }
}

.parking {
  .padding-page-top;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
  &__container {
    .container;
  }
  &__title {
    white-space: pre-wrap;
  }
  &__semicircles {
    position: absolute;
    left: 0;
    bottom: -250px;
    width: 130px;
    height: 411px;
    // overflow: hidden;
    z-index: -1;
    @media @bw1510 {
      bottom: -155px;
      width: 143px;
      height: 310px;
    }
    @media @bw1170 {
      bottom: -140px;
      width: 130px;
      height: 282px;
    }
    @media @bw768 {
      bottom: -95px;
      width: 100px;
      height: 238px;
    }
    @media @bw400 {
      bottom: -70px;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 390px;
      height: 390px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid @white;
      @media @bw1510 {
        width: 294px;
        height: 294px;
      }
      @media @bw1170 {
        width: 265px;
        height: 265px;
      }
      @media @bw768 {
        width: 226px;
        height: 226px;
      }
    }
    &::after {
      right: 52px;
      bottom: 20px;
      border: 1px solid @gold;
      @media @bw960 {
        right: 40px;
      }
    }
  }
  &__semicircles-right {
    position: absolute;
    right: 0;
    top: 42%;
    width: 135px;
    height: 400px;
    // overflow: hidden;
    z-index: -1;
    @media @bw1510 {
      width: 140px;
      height: 303px;
    }
    @media @bw1170 {
      width: 116px;
      height: 250px;
    }
    @media @bw768 {
      top: 31%;
      width: 42px;
      height: 184px;
    }
    @media @bw400 {
      display: none;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 1px;
      bottom: 0;
      width: 390px;
      height: 390px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid @white;
      @media @bw1510 {
        width: 294px;
        height: 294px;
      }
      @media @bw1170 {
        width: 241px;
        height: 241px;
      }
      @media @bw768 {
        width: 176px;
        height: 176px;
      }
    }
    &::after {
      border: 1px solid @gold;
      left: 35px;
      @media @bw1510 {
        left: 50px;
        top: 0;
      }
      @media @bw1170 {
        left: 40px;
        top: 1px;
      }
      @media @bw768 {
        left: 25px;
      }
    }
    &.is-observable {
      &::before,
      &::after {
        transform: scale(0.5);
      }
    }
    &.is-observed {
      &::before,
      &::after {
        transform: scale(0.5);
        animation-name: bounceIn;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
      }
      &::before {
        animation-duration: 2.6s;
      }
      &::after {
        animation-duration: 2.4s;
        animation-delay: 0.2s;
      }
    }
  }
  &__rectangle {
    position: absolute;
    left: 0;
    top: 55%;
    width: 67px;
    height: 289px;

    @media @bw1510 {
      display: none;
    }
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: @gold;
      transform-origin: 0 50%;
    }
    &.is-observable {
      &::after {
        transform: scaleX(0);
      }
    }
    &.is-observed {
      &::after {
        transition: transform 1.5s ease-out;
        transform: scaleX(1);
      }
    }
  }
  &__main-img-wrap {
    margin-right: 13%;
    margin-bottom: 100px;
    position: relative;
    @media @bw1680 {
      margin-right: 100px;
    }
    @media @bw1170 {
      margin-right: 0;
    }
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 0;
      padding-top: 43.113%;
      @media @bw1680 {
        padding-top: 56.355%;
      }
      @media @bw1170 {
        padding-top: 58.593%;
      }
    }
  }
  &__pattern {
    position: absolute;
    right: -63px;
    bottom: -92px;
    width: 127px;
    height: 394px;
    @media @bw1680 {
      right: -49px;
      bottom: -67px;
      width: 98px;
      height: 302px;
    }
    @media @bw1170 {
      right: 0;
      bottom: -62px;
      width: 63px;
      height: 189px;
    }
    @media @bw768 {
      bottom: -66px;
      width: 44px;
      height: 137px;
    }
    @media @bw400 {
      bottom: -48px;
      width: 34px;
      height: 104px;
    }
    &.is-observable {
      &::after {
        transform: scaleX(0);
      }
    }
    &.is-observed {
      &::after {
        transition: transform 1.5s ease-out;
        transform: scaleX(1);
      }
    }
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: @gold;
      transition: transform 1.5s ease-out;
      transform: scaleX(1);
      transform-origin: 100% 50%;
    }
  }
  &__main-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: hidden;
  }
  &__main-subtitle {
    padding: 0 30px;
  }
  &__advant-subtitle {
    padding: 0 30px 0 0;
  }
  &__main-subtitle,
  &__advant-subtitle {
    margin: 0 0 30px;
    font-size: 11px;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 700;
    color: @gold;
  }
  &__advant-items {
    display: flex;
    margin-top: 40px;
    @media @bw768 {
      margin-top: 0;
      margin-bottom: 50px;
      order: 1;
    }
  }
  &__main-text {
    margin: 0;
    padding: 0 30px;
    max-width: 830px;
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.2;
    @media @bw1170 {
      max-width: 550px;
      font-size: 24px;
    }
    @media @bw768 {
      box-sizing: border-box;
      padding: 0 20px;
      max-width: 100%;
      font-weight: 500;
      font-size: 20px;
    }
    @media @bw400 {
      font-size: 18px;
    }
  }
  &__main-img2 {
    margin-top: -40px;
    max-width: 350px;
    width: 100%;
    box-shadow: 0px 50px 50px rgba(51, 57, 66, 0.6);
    border-radius: 2px;
    z-index: -1;
    @media @bw1170 {
      margin-top: -30px;
    }
    @media @bw768 {
      margin-top: -25px;
      max-width: 235px;
    }
    @media @bw400 {
      margin-top: -20px;
    }
  }
  &__main-bgimg {
    align-self: flex-end;
    margin-right: 60px;
    margin-top: -130px;
    margin-bottom: 30px;
    max-width: 1170px;
    width: 100%;
    z-index: -2;
    pointer-events: none;
    @media @bw1510 {
      margin-right: 30px;
      margin-top: -95px;
      max-width: 813px;
    }
    @media @bw1170 {
      margin-top: -70px;
      margin-right: 0;
      max-width: 610px;
    }
    @media @bw768 {
      margin-top: -55px;
      margin-right: -15px;
    }
    @media @bw400 {
      margin-top: -40px;
    }
  }
  &__advant {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    padding: 0 30px;
    @media @bw768 {
      padding: 0 20px;
    }
  }
  &__advant-info {
    margin-right: 150px;
    max-width: 470px;
    width: 100%;
    @media @bw1170 {
      margin-right: 55px;
      max-width: 370px;
    }
    @media @bw768 {
      order: 2;
      max-width: 100%;
    }
  }
  &__advant-inner {
    display: flex;
    @media @bw768 {
      flex-direction: column;
    }
  }
  &__advant-text {
    margin: 0 0 30px;
    font-size: 16px;
    line-height: 1.5;
    color: @white_80;
    &:last-child {
      margin: 0;
    }
  }
  &__security {
    margin-right: 55px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__security-item {
    margin-bottom: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid @gold;
  }
  &__security-caption {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    font-weight: 700;
    color: @white_60;
    font-size: 11px;
    line-height: 1.2;
  }
  &__line {
    margin-bottom: 80px;
    position: relative;
    transform: rotate(-3deg);
    width: 110%;
    height: 57px;
    background-color: @gold;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKYAAAAJCAMAAACPHKd+AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAJcEhZcwAACxMAAAsTAQCanBgAAABCUExURUdwTEM+NkBAOEBAQEI+NkI+NkM+NkI+N0M+N0NAN0I9NkI+N0JANUM9NkI+N0hAOEM/NkI6OkVANUM+NkI9N0M+NzeMmoIAAAAVdFJOUwDvIBB/n1/fv0A/j2CvzyBPHzBvcLedtTAAAAGISURBVDjL1dPLrqQwDIThcuKk7A4B+lLv/6qzgCP1WU1mOd4i/fpABZJkdODgBDD3A+AYJMl2Xs+SZyFJPo8waxV/uyS5d6BeTXJvB2YAgzmIN09gBvCh9hNLl2re1LFJFQgFoHZ60/S+qflUpnpRuHslu8sXmvO7ae5GBOFyNMHFiiA2jT5VF5kPQA2xmwMhJdSAVAc2PVDkqV4unAXw7gvNDtj8aRIYQvCliYspRxBBoLZcZPaSGod67EAwWL+Y7xx6pHoRIxwfcRwrzXdJtUM9DAjmSSKMVm9m0ysIBmopq19TUpSmsikRLOZfTEmOVC/aW+vAo5nlatNVUomQxIKQ6XMzn4wgLNC0sKH7zR9PgJI0EITbF/M8f5gO4PAXis2F5vjVfDY7EFZ2O25m6loryjLzcYV7360GUflrm2E11Yvmtm2H7XmqrW3zaobVIIoagngpbiaaiI88xz8xmwqw6QwC+YuZ9y8kSc/XLkVZZDYVIPUOAtcYMfS5mdUIuMkWcv/P/QFIXBnquISRGQAAAABJRU5ErkJggg==);
    background-size: 166px 9px;
    background-repeat: repeat-x;
    background-position: 0 50%;
    animation: parking-line 10s linear infinite;
  }
  &__info {
    .container;
    box-sizing: border-box;
    margin-top: 80px;
    margin-bottom: 150px;
    display: flex;
    flex-direction: column;
    @media @bw1170 {
      margin-bottom: 100px;
    }
  }
  &__info-text1 {
    align-self: flex-end;
    margin: 0 0 80px;
    max-width: 550px;
    width: 100%;
    text-align: right;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.2;
    @media @bw1170 {
      max-width: 450px;
      font-size: 20px;
    }
    @media @bw768 {
      margin: 0 0 110px;
      max-width: calc(100% - 79px);
    }
    @media @bw400 {
      margin: 0 0 90px;
      max-width: calc(100% - 30px);
      font-size: 18px;
    }
  }
  &__info-text2 {
    margin: 0 110px 0 0;
    align-self: center;
    max-width: 240px;
    width: 100%;
    font-size: 16px;
    line-height: 1.5;
    color: @white_80;
    @media @bw1510 {
      margin: 0;
    }
    @media @bw1170 {
      margin: 0 110px 0 0;
    }
    @media @bw768 {
      margin: 0;
    }
  }
  &__info-bgimg {
    position: absolute;
    bottom: 165px;
    left: 0;
    max-width: 1075px;
    width: 100%;
    z-index: -1;
    pointer-events: none;
    @media @bw1510 {
      bottom: 225px;
      max-width: 747px;
    }
    @media @bw1170 {
      bottom: 190px;
      max-width: 560px;
    }
    @media @bw768 {
      bottom: 205px;
      max-width: 392px;
    }
    @media @bw400 {
      bottom: 210px;
      max-width: 300px;
    }
  }
  &__item-icon {
    margin: auto;
    color: @gold;
  }
  &__container-2500 {
    .container-2500;
  }
  &__info-wrap {
    width: 100%;
    position: relative;
  }
}
</style>
