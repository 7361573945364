<template>
  <main v-if="flat" class="app__page flat">
    <div class="flat__inner">
      <div class="flat__links">
        <localized-link
          :to="{ name: 'flats', query: flatsQuery }"
          class="flat__back r-btn"
        >
          <svg-icon width="14" height="10" name="arrow_left" class="btn-icon" />

          <span class="r-btn__caption">
            {{ $t("pages.flats-flat-id.back") }}
          </span>
        </localized-link>

        <div
          class="flat__share"
          :class="{ 'flat__share--active': shareActive }"
        >
          <button
            type="button"
            class="flat__share-toggle r-btn r-btn--bgc"
            :class="{ 'r-btn--switched': shareActive }"
            @click="toggleShare()"
          >
            <span class="r-btn__wrapper">
              <span class="r-btn__switch">
                <svg-icon
                  width="24"
                  height="24"
                  name="share"
                  class="btn-icon"
                />
              </span>
              <span class="r-btn__switch">
                <svg-icon width="16" height="16" name="x" class="btn-icon" />
              </span>
            </span>
          </button>

          <div class="flat__share-btns">
            <ShareNetwork
              class="flat__share-btn flat__share-btn--fb r-btn"
              network="facebook"
              :url="meta.url"
              :title="meta.title"
              :description="meta.description"
              :media="meta.image"
            >
              <svg-icon
                width="8"
                height="16"
                name="facebook"
                class="btn-icon"
              />
            </ShareNetwork>

            <ShareNetwork
              class="flat__share-btn flat__share-btn--inst r-btn"
              network="twitter"
              :url="meta.url"
              :title="meta.title"
            >
              <svg-icon
                width="17"
                height="14"
                name="twitter"
                class="btn-icon"
              />
            </ShareNetwork>
          </div>

          <span class="flat__share-caption">
            {{ shareActive ? $t("close") : $t("pages.flats-flat-id.share") }}
          </span>
        </div>
      </div>

      <div
        class="flat__details"
        :class="{ 'flat__details--furniture': showFurniture }"
      >
        <div class="flat__scheme">
          <img
            class="flat__scheme-img flat__scheme-img--ps"
            :src="level && flat.layout_1 ? flat.layout_1 : flat.layout"
            @load="loaded += 1"
            alt="layout"
          />
          <img
            class="flat__scheme-img flat__scheme-img--furniture"
            :src="level && flat.furniture_1 ? flat.furniture_1 : flat.furniture"
            @load="loaded += 1"
            alt="furniture"
          />
        </div>

        <div class="flat__line">
          <button
            type="button"
            class="toggle-btn flat__f-toggle"
            :class="{
              'toggle-btn--on': showFurniture
            }"
            :data-on="$t('params.with_furniture')"
            :data-off="$t('params.without_furniture')"
            @click="toggleFurniture()"
          >
            <span class="toggle"></span>
          </button>

          <button
            v-if="flat.floor_1"
            type="button"
            class="toggle-btn flat__l-toggle"
            :class="{
              'toggle-btn--on': level
            }"
            :data-on="$t('level')"
            :data-off="$t('level')"
            @click="toggleLevel()"
          >
            <span class="toggle toggle--gold">
              <span class="toggle__caption" data-off="1" data-on="2"></span>
            </span>
          </button>

          <div class="flat__compass compass">
            <svg-icon
              width="36"
              height="36"
              name="compass"
              class="compass__icon"
            />
            <span class="compass__pointer compass__pointer--n">
              {{ $t("compass.n") }}
            </span>
            <span class="compass__pointer compass__pointer--s">
              {{ $t("compass.s") }}
            </span>
            <span class="compass__pointer compass__pointer--w">
              {{ $t("compass.w") }}
            </span>
            <span class="compass__pointer compass__pointer--e">
              {{ $t("compass.e") }}
            </span>
          </div>
        </div>

        <div class="flat__btns">
          <localized-link to="/design" class="flat__design r-btn">
            <svg-icon width="18" height="22" name="roller" class="btn-icon" />

            <span class="r-btn__caption nowrap">
              {{ $t("design") }}
            </span>
          </localized-link>
          <a :href="pdfPath" target="_blank" class="r-btn flat__download-btn">
            <svg-icon width="24" height="24" name="download" class="btn-icon" />

            <span class="r-btn__caption nowrap">
              {{ $t("pages.flats-flat-id.btn_download") }}
              pdf
            </span>
          </a>
        </div>

        <div class="flat__characteristics">
          <h1 class="flat__sub-title">
            {{ $t("pages.flats-flat-id.title_about") }}
          </h1>

          <div class="flat__specs">
            <div class="flat__specs-list">
              <div class="flat__characteristic">
                <span class="flat__characteristic-number">
                  {{
                    flat.floor + (flat.layout_1 ? "/" + (+flat.floor + 1) : "")
                  }}
                </span>
                <span class="flat__characteristic-info">
                  {{ $t("params.floor") }}
                </span>
              </div>
              <div class="flat__characteristic">
                <span class="flat__characteristic-number">{{ flat.id }}</span>
                <span class="flat__characteristic-info">
                  {{ $t("params.id") }}
                </span>
              </div>
              <div class="flat__characteristic">
                <span class="flat__characteristic-number">{{
                  flat.rooms
                }}</span>
                <span class="flat__characteristic-info">
                  {{ $t("params.rooms") }}
                </span>
              </div>
              <div class="flat__characteristic">
                <span class="flat__characteristic-number">{{ flat.area }}</span>
                <span class="flat__characteristic-info">
                  {{ $t("params.area") }}
                </span>
              </div>
              <div class="flat__characteristic">
                <span class="flat__characteristic-number">
                  {{ $t("finish." + flat.finish) }}
                </span>
                <span class="flat__characteristic-info">
                  {{ $t("params.finish") }}
                </span>
              </div>
              <div class="flat__characteristic">
                <span class="flat__characteristic-number">
                  {{ $t("status." + flat.status) }}
                </span>
                <span class="flat__characteristic-info">
                  {{ $t("params.status_caption") }}
                </span>
              </div>
              <div class="flat__characteristic">
                <button
                  type="button"
                  class="flat__characteristic-btn"
                  :class="{
                    'flat__characteristic-btn--disabled': flat.status === 'sold'
                  }"
                  @click="openRequestFlat()"
                >
                  {{
                    flat.price_on_request
                      ? $t("requestPrice")
                      : $global.numberSpaces(flat.price) || "-"
                  }}
                </button>
                <span class="flat__characteristic-prompt">
                  {{ $t("pages.flats-flat-id.btn_request") }}
                </span>

                <!-- <span v-else class="flat__characteristic-number">
                  {{ $global.numberSpaces(flat.price) || "-" }}
                </span> -->
                <span class="flat__characteristic-info">
                  {{ $t("params.price") }}
                </span>
              </div>
            </div>
            <div v-if="!flat.price_on_request" class="flat__note-wrap">
              <sup class="flat__note-sup">*</sup>
              <localized-link to="/documentation" class="flat__note-link">
                {{ $t("price_note") }}
              </localized-link>
            </div>
          </div>
        </div>
      </div>

      <aside class="flat__aside">
        <div
          v-if="floor"
          class="flat__floor"
          :class="{ 'flat__floor--active': openPlanActive }"
        >
          <button type="button" class="flat__floor-btn" @click="openPlan()">
            {{ $t("pages.flats-flat-id.title_plan") }}
          </button>

          <div class="flat__floor-schema">
            <div class="flat__floor-caption">
              {{ $t("pages.flats-flat-id.title_plan") }}
            </div>
            <div class="flat__floor-img-wrapper">
              <img
                class="flat__floor-img"
                :src="'/base/floors/' + (+flat.floor + level) + '.svg'"
                @load="loaded += 1"
              />

              <svg
                class="flat__floor-highlight"
                viewBox="0 0 548 548"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @mouseleave="captionShow = -1"
              >
                <g fill="#6ECFA0">
                  <template v-for="(flt, fltIndex) in floor" :key="flt.id">
                    <path
                      class="flat__floor-path"
                      :class="{
                        'flat__floor-path--active': flt.id == flat_id,
                        'flat__floor-path--reserved': flt.status === 'reserved',
                        'flat__floor-path--sold': flt.status === 'sold',
                        'flat__floor-path--rent': flt.status === 'rent'
                      }"
                      :d="flt.highlight"
                      @click="goTo(flt)"
                      @mouseenter="captionShow = fltIndex"
                    />
                  </template>
                </g>
              </svg>

              <svg
                class="flat__floor-captions"
                viewBox="0 0 548 548"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <filter
                    x="-0.1"
                    y="-0.08"
                    width="1.2"
                    height="1.2"
                    id="bg-text"
                  >
                    <feFlood flood-color="#0D0D0C" />
                    <feComposite in="SourceGraphic" operator="xor" />
                  </filter>
                </defs>
                <template v-for="(fl, index) in floor" :key="fl.id">
                  <g
                    class="flat__floor-word"
                    :class="{
                      'flat__floor-word--show': index === captionShow,
                      'flat__floor-word--active': fl.id == flat_id
                    }"
                  >
                    <text
                      filter="url(#bg-text)"
                      fill="transparent"
                      :x="fl.textX"
                      :y="fl.textY"
                    >
                      {{
                        fl.status === "free"
                          ? $t("status.free")
                          : fl.status === "reserved"
                          ? $t("status.reserved")
                          : fl.status === "rent"
                          ? $t("status.rent")
                          : $t("status.sold")
                      }}
                    </text>
                    <text fill="#F3F3F2" :x="fl.textX" :y="fl.textY">
                      {{
                        fl.status === "free"
                          ? $t("status.free")
                          : fl.status === "reserved"
                          ? $t("status.reserved")
                          : fl.status === "rent"
                          ? $t("status.rent")
                          : $t("status.sold")
                      }}
                    </text>
                  </g>
                </template>
              </svg>
            </div>
            <div class="flat__schema-info">
              <div class="flat__info-item">
                {{ $t("status.free") }}
              </div>
              <div class="flat__info-item flat__info-item--rent">
                {{ $t("status.rent") }}
              </div>
              <div class="flat__info-item flat__info-item--res">
                {{ $t("status.reserved") }}
              </div>
              <div class="flat__info-item flat__info-item--sold">
                {{ $t("status.sold") }}
              </div>
            </div>
          </div>
        </div>

        <button
          type="button"
          class="flat__btn-request btn"
          :class="{ 'btn--disabled': flat.status === 'sold' }"
          @click="openRequestFlat()"
        >
          {{ $t("pages.flats-flat-id.btn_request") }}
        </button>

        <a
          href="#"
          target="_blank"
          class="btn btn--tr flat__mobile-download-btn"
        >
          {{ $t("pages.flats-flat-id.btn_download") }}
          pdf
        </a>
      </aside>
    </div>

    <RequestFlat :flat_id="flat_id" />
  </main>
</template>

<script>
import { pageLoaded } from "@/mixins/pageLoaded.js";
import RequestFlat from "@/components/RequestFlat";
import { clearFilters } from "@/mixins/clearFilters.js";

let flatsQuery = {};

export default {
  name: "flat",
  components: {
    RequestFlat
  },
  mixins: [pageLoaded, clearFilters],
  data() {
    return {
      showFurniture: true,
      flat_id: 0,
      level: 0,
      flatsQuery: {},
      loadQty: 4, // смонтировано + 3 изображения
      captionShow: -1
    };
  },
  computed: {
    meta() {
      return {
        title:
          "Panorama Residence | " +
          this.$t("pages.flats-flat-id.title") +
          " " +
          this.flat_id,
        description: this.$t("description"),
        url:
          this.$store.state.domain +
          this.$localizePath(this.$route.path, this.$i18n.locale),
        image: this.$store.state.domain + "/img/panorama.jpg"
      };
    },
    shareActive() {
      return this.$store.state.shareActive;
    },
    openPlanActive() {
      return this.$store.state.openPlanActive;
    },
    flats() {
      return this.$store.state.flats;
    },
    flat() {
      if (!this.flat_id || !this.flats || !this.flats[this.flat_id])
        return null;
      return this.flats[this.flat_id];
    },
    floor() {
      if (!this.flat) return null;
      return this.$store.getters.floors[+this.flat.floor + this.level];
    },
    locale() {
      return this.$i18n.locale;
    },
    pdfPath() {
      return (
        this.$store.state.domain +
        "/" +
        (this.flat.pdf_path[this.locale]
          ? this.flat.pdf_path[this.locale]
          : `pdf/flat/${this.locale}/${this.flat_id}.pdf`)
      );
    }
  },
  methods: {
    openRequestFlat() {
      if (this.flat.status === "sold") return;
      this.$store.state.requestActive = true;
    },
    toggleShare() {
      this.$store.state.shareActive = !this.$store.state.shareActive;
    },
    toggleFurniture() {
      this.showFurniture = !this.showFurniture;
    },
    toggleLevel() {
      this.level = this.level == 0 ? 1 : 0;
    },
    openPlan() {
      this.$store.state.openPlanActive = !this.$store.state.openPlanActive;
    },
    goTo(flat) {
      if (this.flat_id == flat.id) return;
      if (flat.status !== "sold") this.$router.push("/flats/flat/" + flat.id);
    },
    checkID() {
      if (this.$route.name !== "flats-flat-id") return;
      if (
        this.$route.params.id &&
        +this.$route.params.id > 0 &&
        +this.$route.params.id < 222
      ) {
        this.level = 0;
        this.flat_id = this.$route.params.id;
        this.flatsQuery = flatsQuery;
        this.$store.state.flat_id = this.flat_id;
      } else {
        this.$router.replace("/flats");
      }
    }
  },
  created() {
    this.checkID();
  },
  mounted() {
    this.$nextTick(() => {
      this.loaded = this.loaded + 1;
    });
  },
  watch: {
    $route() {
      this.checkID();
    }
  },
  beforeRouteEnter(to, from, next) {
    flatsQuery =
      from.name === "flats" && from.query.layouts ? { layouts: 1 } : {};
    next();
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "flats" && to.name !== "flats-flat-id") {
      this.clearFilters();
    }
    next();
  }
};
</script>

<style lang="less">
.flat {
  .container-flat;
  box-sizing: border-box;
  display: flex;
  padding: 200px 50px 30px 18%;
  @media @bw1510 {
    padding: 135px 30px 30px 19%;
  }
  @media @bw1170 {
    padding: 135px 30px 50px;
  }
  @media @bw1020 {
    padding: 30px 30px 50px;
  }
  @media @bw768 {
    padding: 30px 20px 40px;
  }
  @media @w1510bh750 {
    padding: 135px 30px 30px 19%;
  }
  &__inner {
    display: flex;
    position: relative;
    width: 100%;
    flex-grow: 1;
    @media @bw1170 {
      flex-direction: column;
    }
  }
  &__links {
    display: flex;
    flex-direction: column;
    @media @bw1170 {
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-bottom: 30px;
    }
  }

  &__back {
    position: fixed;
    top: 180px;
    left: 50px;
    @media @bw1510 {
      left: 30px;
      top: 135px;
    }
    @media @w1510bh750 {
      left: 30px;
      top: 135px;
    }
    @media @bw1170 {
      position: relative;
      top: auto;
      left: auto;
      margin-right: auto;
    }
  }

  &__share-caption {
    display: block;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.3;
    color: @white;
    transition: color 0.2s;
    margin-top: 10px;
    @media @w1510bh750 {
      margin-top: 20px;
    }
    @media @bw1340 {
      margin-top: 20px;
    }
    @media @bw1170 {
      margin-top: 0;
    }
    @media @bw768 {
      display: none;
    }
  }
  &__share-toggle {
    display: none;
    @media @bw1340 {
      display: flex;
    }
    @media @bw1170 {
      display: none;
    }
    @media @bw768 {
      display: flex;
      z-index: 5;
    }
    @media @w1510bh750 {
      display: flex;
      z-index: 5;
    }
  }

  &__share {
    position: fixed;
    left: 50px;
    bottom: 50px;
    display: flex;
    flex-direction: column;
    @media @bw1510 {
      left: 30px;
      bottom: 30px;
    }
    @media @w1510bh750 {
      left: 30px;
      bottom: 30px;
    }
    @media @bw1170 {
      position: static;
      flex-direction: row;
      align-items: center;
    }
    @media @bw768 {
      position: relative;
      left: 0;
      top: 0;
      width: 54px;
      height: 54px;
      border-radius: 50%;
    }
    @media @bw400 {
      width: 44px;
      height: 44px;
    }
    &--active {
      @media @bw768 {
        & .flat__share-btn {
          transform: none;
          opacity: 1;
          pointer-events: auto;
        }
      }
      @media @w1510bh750 {
        & .flat__share-btns {
          display: flex;
        }
      }
      @media @bw1340 {
        & .flat__share-btns {
          display: flex;
        }
      }
    }
  }
  &__share-btns {
    @media @w1510bh750 {
      display: none;
      position: absolute;
      top: -20px;
      left: 72px;
      padding: 19px 20px;
      background-color: #505a68;
      border-radius: 20px;
    }
    @media @bw1340 {
      display: none;
      position: absolute;
      top: -20px;
      left: 72px;
      padding: 19px 20px;
      background-color: #505a68;
      border-radius: 20px;
    }
    @media @bw1170 {
      display: flex;
      padding: 0;
      background-color: transparent;
      position: static;
    }
    &::before {
      @media @w1510bh750 {
        content: "";
        position: absolute;
        top: 39px;
        left: -7px;
        width: 16px;
        height: 16px;
        border-radius: 4px;
        background-color: #505a68;
        transform: rotate(45deg);
      }
      @media @bw1340 {
        content: "";
        position: absolute;
        top: 39px;
        left: -7px;
        width: 16px;
        height: 16px;
        border-radius: 4px;
        background-color: #505a68;
        transform: rotate(45deg);
      }
      @media @bw1170 {
        display: none;
      }
    }
  }
  &__share-btn {
    margin-bottom: 20px;
    @media @bw1340 {
      margin-right: 20px;
      margin-bottom: 0;
    }
    @media @bw1170 {
      margin-right: 20px;
      margin-bottom: 0;
    }
    @media @w1510bh750 {
      margin-right: 20px;
      margin-bottom: 0;
    }
    @media @bw768 {
      margin-right: 0;
      position: absolute;
      left: -74px;
      top: 0;
      right: 0;
      bottom: 0;
      transform: translateX(74px);
      transition: transform 0.3s linear, opacity 0.5s;
      transform-origin: 100% 0;
      pointer-events: none;
      opacity: 0;
    }
    @media @bw400 {
      left: -59px;
    }
    &--fb {
      @media @bw768 {
        left: -148px;
        transform: translateX(148px);
      }
      @media @w1510bh750 {
        left: -148px;
        transform: translateX(148px);
      }
      @media @bw400 {
        left: -118px;
      }
    }
    &--inst {
      @media @w1510bh750 {
        margin-right: 0;
      }
      @media @bw1340 {
        margin-right: 0;
      }
      @media @bw1170 {
        margin-right: 20px;
      }
    }
  }
  &__details {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 60px;
    width: 60%;
    @media @bw1510 {
      padding-right: 30px;
    }
    @media @bw1170 {
      width: 100%;
      margin-bottom: 40px;
      padding-right: 0;
      padding-bottom: 10px;
      border-bottom: 1px solid @white_10;
    }
    &--furniture {
      & .flat__scheme-img--furniture {
        display: block;
      }
    }
  }
  &__scheme {
    flex-grow: 1;
    display: block;
    position: relative;
    margin-bottom: auto;
    width: 100%;
    @media @bw1170 {
      margin-bottom: 40px;
      align-self: center;
      max-width: 430px;
      width: 100%;
      height: 100%;
    }
  }
  &__scheme-img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 1;
    &--furniture {
      display: none;
      z-index: 0;
    }
    &--ps {
      @media @bw1170 {
        position: static;
      }
    }
  }
  &__characteristics {
    @media @bw1170 {
      align-self: flex-start;
    }
  }
  &__specs {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    flex-shrink: 0;
    @media @bw1170 {
      margin-top: 0;
      align-items: flex-start;
    }
  }
  &__specs-list {
    display: flex;
    @media @bw1170 {
      flex-wrap: wrap;
    }
  }
  &__note-wrap {
    margin-top: 25px;
    @media @bw1170 {
      margin: 0 0 25px;
    }
    @media @w1510bh750 {
      margin-top: 20px;
    }
  }
  &__note-sup,
  &__note-link {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: @gold;
  }
  &__note-sup {
    margin-right: 5px;
  }
  &__note-link {
    transition: text-decoration-color 0.2s;
    text-align: center;
    @media @bw1170 {
      text-align: left;
    }

    &:hover,
    &:active {
      text-decoration-color: transparent;
    }
  }
  &__sub-title {
    display: none;
    @media @bw1170 {
      display: block;
      margin: 0 0 30px;
      font-family: @font2;
      font-weight: 700;
      font-size: 36px;
      line-height: 1;
    }
  }
  &__characteristic {
    position: relative;
    margin-right: 30px;
    flex-shrink: 0;
    @media @bw1340 {
      margin-right: 20px;
    }
    @media @bw1170 {
      margin-bottom: 20px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
  &__characteristic-number,
  &__characteristic-btn {
    display: block;
    padding: 0;
    border: none;
    background: none;
    font-family: @font2;
    font-weight: 700;
    font-size: 46px;
    line-height: 1;
    @media @bw1510 {
      font-size: 28px;
    }
    @media @w1510bh750 {
      font-size: 28px;
    }
    @media @bw1020 {
      margin-bottom: 6px;
    }
  }
  &__characteristic-btn {
    transition: color 0.2s;
    text-decoration: underline;
    &--disabled {
      text-decoration: none;
      pointer-events: none;
    }
    &:active {
      color: @gold;
    }
    &:hover {
      @media (hover: hover) {
        color: @gold;
        & + .flat__characteristic-prompt {
          opacity: 1;
        }
      }
    }
  }
  &__characteristic-info {
    display: block;
    font-family: @font1;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.8;
    color: @white_60;
    @media @bw1340 {
      font-size: 12px;
    }
  }
  &__aside {
    box-sizing: border-box;
    position: relative;
    width: 40%;
    margin: 0 auto;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media @bw1170 {
      margin: 0;
      padding-top: 0;
      width: 100%;
    }
  }

  &__floor {
    flex-grow: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 0 40px;
    @media @bw1170 {
      margin-bottom: 40px;
      padding: 0 0 30px;
      border-bottom: 1px solid @white_10;
    }
    &--active {
      @media @bw1170 {
        & .flat__floor-btn::after {
          transform: scale3d(1, -1, 1);
        }
        & .flat__floor-schema {
          display: flex;
        }
      }
    }
  }
  &__floor-btn {
    display: none;
    @media @bw1170 {
      box-sizing: border-box;
      display: block;
      position: relative;
      padding: 0 20px 0 0;
      border: none;
      outline: none;
      width: 100%;
      background-color: transparent;
      text-align: left;
      font-family: @font2;
      font-weight: 700;
      font-size: 36px;
      line-height: 1;
    }
    &::after {
      display: none;
      @media @bw1170 {
        display: block;
        content: "";
        position: absolute;
        right: 0;
        margin-top: -4px;
        top: 50%;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSAwLjk5OTk5OUw2IDZMMSAxIiBzdHJva2U9IiNGM0YzRjIiIHN0cm9rZS13aWR0aD0iMS40Ii8+Cjwvc3ZnPgo=);
        background-repeat: no-repeat;
        width: 12px;
        height: 7px;
        transition: transform 0.2s;
      }
    }
  }
  &__floor-schema {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 370px;
    margin: auto 0;
    @media @bw1170 {
      display: none;
      max-height: none;
      margin: 30px 0 0;
    }
  }
  &__floor-caption {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: @white_60;
  }
  &__floor-img-wrapper {
    margin-bottom: 30px;
    flex-grow: 1;
    position: relative;
    width: 100%;
    max-width: 330px;
    max-height: 330px;
    @media @bw1340 {
      margin-bottom: 25px;
      max-width: 260px;
      max-height: 260px;
    }
    @media @bw1170 {
      max-width: 346px;
      max-height: 346px;
    }
    @media @w1510bh750 {
      margin-bottom: 25px;
    }
  }
  &__floor-img {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    @media @bw1170 {
      position: static;
    }
  }
  &__floor-highlight,
  &__floor-captions {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
  }

  &__floor-captions {
    font-family: @font1;
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    pointer-events: none;
    @media @bw1340 {
      font-size: 20px;
    }
    @media @bw1170 {
      font-size: 16px;
    }
    @media @bw370 {
      font-size: 18px;
    }
  }

  &__floor-path {
    opacity: 0.15;
    cursor: pointer;
    transition: opacity 0.2s;
    &:active {
      opacity: 0.4;
    }
    &:hover {
      @media (hover: hover) {
        opacity: 0.4;
      }
    }
    &--active {
      opacity: 0.4;
    }
    &--reserved {
      fill: @gold;
    }
    &--sold {
      cursor: default;
      fill: #f36363;
      &:hover {
        @media (hover: hover) {
          opacity: 0.15;
        }
      }
    }
    &--rent {
      fill: #728bcb;
    }
  }

  &__line {
    position: absolute;
    right: 0;
    display: flex;
    align-items: flex-start;
    width: 40%;
    @media @bw1170 {
      position: static;
      right: auto;
      margin-bottom: 40px;
      width: 100%;
    }
  }
  &__compass {
    margin-left: auto;
  }
  &__f-toggle {
    margin-right: 40px;
    z-index: 5;
  }
  &__l-toggle {
    margin-right: auto;
    z-index: 5;
  }
  &__btn-request {
    max-width: 300px;
    width: 100%;
    margin-top: auto;
    @media @bw1510 {
      max-width: 280px;
      height: 60px;
    }
    @media @bw1170 {
      max-width: 710px;
      height: 78px;
      margin-bottom: 20px;
    }
    @media @w1510bh750 {
      max-width: 280px;
      height: 60px;
    }
    &:last-child {
      @media @bw1170 {
        margin-bottom: 0;
      }
    }
  }
  &__btns {
    position: fixed;
    left: 50px;
    top: 252px;
    bottom: 226px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    @media @bw1510 {
      left: 30px;
    }
    @media @bw1170 {
      position: relative;
      left: 0;
      top: 0;
      bottom: 0;
      margin: 0 0 40px;
      align-self: flex-start;
    }
    @media @w1510bh750 {
      left: 30px;
      top: 190px;
      bottom: 206px;
    }
  }
  &__download-btn {
    @media @bw1170 {
      display: none;
    }
  }
  &__mobile-download-btn {
    display: none;
    @media @bw1170 {
      display: flex;
      width: 100%;
      max-width: 710px;
    }
  }
  &__design {
    margin-bottom: 20px;
  }
  &__floor-word {
    opacity: 0;
    transition: opacity 0.2s;
    @media @bw1170 {
      opacity: 1;
    }
    &--show {
      opacity: 1;
    }
    &--active {
      opacity: 1;
    }
  }
  &__schema-info {
    display: flex;
    @media @bw1170 {
      display: none;
    }
  }
  &__info-item {
    margin-right: 20px;
    position: relative;
    padding-left: 15px;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.2;
    text-transform: uppercase;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -5px;
      left: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: @gold;
      opacity: 0.4;
    }
    &--rent {
      &::before {
        background-color: @blue;
      }
    }
    &--res {
      &::before {
        background-color: @green2;
      }
    }
    &--sold {
      &::before {
        background-color: @red;
      }
    }
  }
  &__characteristic-prompt {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 120%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 5px 7px;
    text-align: center;
    border-radius: 7px;
    font-size: 12px;
    font-weight: 500;
    background-color: #646b72;
    opacity: 0;
    transition: opacity 0.2s;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -5px;
      margin: 0 auto;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      background-color: #646b72;
    }
  }
}
</style>
