<template>
  <main class="app__page developer">
    <div class="developer__container">
      <h1 class="developer__title title title--bb">
        {{ $t("pages.developer.title") }}
      </h1>
      <img
        class="developer__main-img"
        src="/img/developer/developer1.jpg"
        alt="developer1"
        @load="loaded += 1"
      />

      <div v-if="pageLocale" class="developer__content-wrapper">
        <div class="developer__bg-img bg-img"></div>
        <div class="developer__semicircles is-observable"></div>
        <div class="developer__rectangle is-observable"></div>

        <p class="developer__main-text is-observable">
          <span class="d-block observe-target observe-80-bottom">
            {{ pageLocale.main_paragraph }}
          </span>
        </p>
        <div class="developer__info">
          <p class="developer__info-text is-observable">
            <span class="d-block observe-target observe-80-bottom">
              {{ pageLocale.about }}
            </span>
          </p>
          <div class="developer__info-img-wrap">
            <img
              src="/img/developer/developer2.jpg"
              alt="developer2"
              class="developer__info-img"
            />
          </div>
        </div>
        <div class="developer__info2">
          <div class="developer__info2-img-wrap is-observable">
            <img
              src="/img/developer/developer3.jpg"
              alt="developer3"
              class="developer__info2-img observe-target observe-80-bottom"
            />
          </div>
          <div class="developer__info2-block is-observable">
            <div
              class="
                developer__info2-item
                observe-target observe-80-bottom
                delay-02
              "
            >
              <h4 class="developer__info2-subtitle">
                {{ pageLocale.text }}
              </h4>
              {{ pageLocale.text2 }}
            </div>
          </div>
          <div class="developer__bg-wrap">
            <img
              class="developer__bg-word"
              src="/img/developer/developer_bg.svg"
              alt="developer_bg"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="developer__container-2500">
      <localized-link to="/documentation" class="next-page">
        <span class="next-page__capiton">{{ $t("next") }}</span>
        <span class="next-page__name">
          <span class="next-page__title">
            {{ $t("pages.documentation.title") }}
          </span>
          <span class="next-page__btn r-btn">
            <svg-icon
              width="14"
              height="10"
              name="arrow_right"
              class="btn-icon"
            />
          </span>
        </span>
      </localized-link>
    </div>
    <PageFooter />
  </main>
</template>

<script>
import { pageLocale } from "@/mixins/pageLocale.js";
import { observer } from "@/mixins/observer.js";
import PageFooter from "@/components/PageFooter";
export default {
  name: "developer",
  components: { PageFooter },
  mixins: [pageLocale, observer],
  data() {
    return {
      loadQty: 3 // смонтировано + перевод + 1 изображение
    };
  }
};
</script>

<style lang="less">
.developer {
  .padding-page-top;
  position: relative;
  overflow: hidden;
  &__semicircles {
    position: absolute;
    right: 0;
    top: 44%;
    width: 161px;
    height: 380px;
    z-index: -1;
    @media @bw1680 {
      width: 110px;
      height: 297px;
    }
    @media @bw1510 {
      top: 42%;
    }
    @media @bw1170 {
      top: 38%;
      width: 90px;
      height: 191px;
    }
    @media @bw768 {
      display: none;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 1px;
      width: 380px;
      height: 380px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid @gold;
      @media @bw1680 {
        width: 280px;
        height: 280px;
      }
      @media @bw1170 {
        width: 180px;
        height: 180px;
      }
    }
    &::after {
      border: 1px solid @white;
      top: 20px;
      left: 60px;
      @media @bw1680 {
        top: 13px;
        left: 43px;
      }
      @media @bw1170 {
        top: 11px;
        left: 27px;
      }
    }
    &.is-observable {
      &::before,
      &::after {
        transform: scale(0.5);
      }
    }
    &.is-observed {
      &::before,
      &::after {
        transform: scale(0.5);
        animation-name: bounceIn;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
      }
      &::before {
        animation-duration: 2.6s;
      }
      &::after {
        animation-duration: 2.4s;
        animation-delay: 0.2s;
      }
    }
  }
  &__rectangle {
    position: absolute;
    left: 0;
    top: 45%;
    width: 62px;
    height: 232px;

    @media @bw1510 {
      display: none;
    }
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: @gold;
      transform-origin: 0 50%;
    }
    &.is-observable {
      &::after {
        transform: scaleX(0);
      }
    }
    &.is-observed {
      &::after {
        transition: transform 1.5s ease-out;
        transform: scaleX(1);
      }
    }
  }
  &__container {
    .container;
  }
  &__main-img {
    margin-bottom: 100px;
    width: 100%;
    box-shadow: 0px 50px 100px rgba(51, 57, 66, 0.6);
    border-radius: 2px;
    @media @bw1170 {
      margin-bottom: 80px;
    }
    @media @bw768 {
      margin-bottom: 60px;
    }
    @media @bw400 {
      margin-bottom: 50px;
    }
  }
  &__main-text {
    margin: 0 0 60px;
    text-align: left;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.2;
    @media @bw1510 {
      max-width: 780px;
      width: 100%;
    }
    @media @bw1170 {
      margin-bottom: 40px;
      max-width: 530px;
      font-size: 24px;
    }
    @media @bw768 {
      margin-bottom: 30px;
      max-width: 100%;
      font-weight: 500;
      font-size: 20px;
    }
    @media @bw400 {
      font-size: 18px;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
  }
  &__info-text {
    margin: 0 0 0 210px;
    font-weight: 400;
    max-width: 432px;
    width: 100%;
    font-size: 16px;
    line-height: 1.5;
    color: @white_80;
    @media @bw1510 {
      margin: 0 0 0 150px;
    }
    @media @bw1170 {
      margin: 0 0 55px 70px;
    }
    @media @bw768 {
      box-sizing: border-box;
      margin: 0 0 50px 0;
      padding-left: 70px;
      max-width: 100%;
    }
    @media @bw400 {
      padding-left: 30px;
    }
  }
  &__info-img-wrap {
    position: relative;
    align-self: flex-end;
    box-sizing: border-box;
    padding-right: 29px;
    width: 430px;
    box-shadow: 0px 50px 100px rgba(51, 57, 66, 0.6);
    border-radius: 2px;
    @media @bw1510 {
      padding-right: 27px;
      width: 332px;
    }
    @media @bw1170 {
      padding-right: 23px;
      width: 291px;
    }
    @media @bw768 {
      margin-bottom: 70px;
      width: 280px;
    }
    @media @bw400 {
      margin-bottom: 60px;
      padding-right: 20px;
      width: 250px;
    }
    &::before {
      content: "";
      position: absolute;
      border: 1px solid @gold;
      top: 27px;
      bottom: 27px;
      left: 29px;
      right: 0px;
      z-index: -1;
      @media @bw1510 {
        top: 31px;
        bottom: 31px;
        left: 27px;
      }
      @media @bw1170 {
        top: 28px;
        bottom: 28px;
        left: 23px;
      }
      @media @bw400 {
        top: 24px;
        bottom: 24px;
        left: 20px;
      }
    }
  }
  &__info2 {
    display: flex;
    align-items: flex-start;
    margin-top: -100px;
    margin-bottom: 150px;
    position: relative;
    padding-bottom: 182px;
    @media @bw1510 {
      margin-top: -30px;
      padding-bottom: 135px;
    }
    @media @bw1170 {
      margin-bottom: 100px;
      padding-bottom: 0px;
    }
    @media @bw768 {
      margin-top: 0px;
      margin-bottom: 80px;
      flex-direction: column;
    }
  }
  &__info2-img-wrap {
    margin-right: 75px;
    max-width: 580px;
    width: 100%;
    @media @bw1510 {
      margin-right: 50px;
      max-width: 462px;
    }
    @media @bw1170 {
      margin-right: 35px;
      max-width: 364px;
    }
    @media @bw768 {
      order: 2;
      max-width: 374px;
    }
  }
  &__info2-img {
    display: block;
    width: 100%;
    box-shadow: 0px 50px 100px rgba(51, 57, 66, 0.6);
  }
  &__info2-block {
    margin: 200px 0 0;
    @media @bw1510 {
      margin-top: 140px;
    }
    @media @bw1170 {
      margin-top: 95px;
    }
    @media @bw768 {
      margin-top: 0;
      margin-bottom: 70px;
    }
    @media @bw400 {
      margin-bottom: 60px;
    }
  }
  &__info2-subtitle {
    margin: 0 0 40px;
    font-weight: 700;
    font-size: 26px;
    line-height: 1.2;
    color: @white;
    @media @bw1510 {
      font-size: 24px;
    }
  }
  &__info2-item {
    margin-bottom: 40px;
    text-align: right;
    font-size: 20px;
    color: @gold;
    white-space: pre-wrap;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__info2-item-title {
    margin: 0 0 27px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 11px;
    line-height: 1.2;
    color: @gold;
  }
  &__info2-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__info2-li {
    position: relative;
    margin: 0 0 10px;
    padding-left: 17px;
    font-size: 16px;
    line-height: 1.5;
    color: @white_80;
    &:last-child {
      margin: 0;
    }
    &::before {
      content: "";
      position: absolute;
      top: 10px;
      left: 0;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: @gold;
    }
  }
  &__bg-wrap {
    position: absolute;
    bottom: 0;
    z-index: -1;
    pointer-events: none;
    @media @bw1170 {
      bottom: 100px;
    }
    @media @bw768 {
      top: 200px;
      bottom: auto;
    }
    @media @bw400 {
      top: 150px;
    }
  }
  &__container-2500 {
    .container-2500;
  }
}
</style>
